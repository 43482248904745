import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import Bound from '@opidcore/components/Bound'
import Button from '@opidcore/components/Button'
import Icon from '@opidcore/components/Icon'
import Loading from '@opidcore/components/Loading'
import { Tabular, TabularColumn } from '@opidcore/components/Tabular'
import { useNavBar } from '@opidcore/hooks/WTF'
import Converter from '../../../utilities/UnitConversion'

import PeriodSelect from "../../components/PeriodSelect"

import { NiceCurrency, NicePercent } from '../Nice'

import moment from 'moment'
import FullTextSearchable from '../../components/FullTextSearchable'
import { ActionBar, HoverElement } from '@opidcore/components'
import ReasonableFilter from '../../components/ReasonableFilter'

export default function ClientHealth(props) {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [period, setPeriod] = useState(moment().format("YYYY-MM"))
    const [endPeriod, setEndPeriod] = useState(moment().format("YYYY-MM"))
    const [loading, setLoading] = useState(true)
    const [groupBy, setGroupBy] = useState(null)
    const [entryTotal, setEntryTotal] = useState(null)
    const [arTotal, setARTotal] = useState(null)
    const [apTotal, setAPTotal] = useState(null)
    const [marginTotal, setMarginTotal] = useState(null)

    const stateRef = useRef({})

    const updateRef = (them, magic, key) => {
        setPeriod(stateRef.current.startPeriod)
        setEndPeriod(stateRef.current.endPeriod)
        setLoading(true)
    }

    useNavBar("Client Health")

    useEffect(() => {
        APP.central.ClientHealth.getClientHealthData(period, endPeriod, groupBy).then((r) => {
            setData(r.result.data.rows)
            setFilteredData(r.result.data.rows)
            setLoading(false)
        })
    }, [period, endPeriod, groupBy])

    useEffect(() => {
        setEntryTotal(filteredData.length)
        var tempARTotal = 0;
        var tempAPTotal = 0;
        var tempMarginTotal = 0;
        filteredData.forEach((row) => {
            tempARTotal += row.ar_total
            tempAPTotal += row.ap_total
            tempMarginTotal += row.margin
        })
        setARTotal(tempARTotal.toFixed(0))
        setAPTotal(tempAPTotal.toFixed(0))
        setMarginTotal(tempMarginTotal.toFixed(0))
    }, [data, filteredData])

    const openDataModal = (row) => {
        APP.instance.createModal(<ClientHealthDataView data={row}/>)
    }

    const ClientHealthDataView = (props) => {

        useEffect(() => {
            console.log("more data")
            APP.central.ClientHealth.getDetailedClientHealthData(props.data.customer_id).then((r) => {
                console.log(r)
            })
        })
        return <div>
            
        </div>
    }

    const openInvoiceLinks = (row) => {
        APP.instance.createModal(<InvoiceLinks row={row} />)
    }

    const InvoiceLinks = (props) => {
        console.log(props.row)
        return <div>
            <h1>AP Invoices</h1>
            {props.row.ap_ids.split(", ").map((id) => {
                return <div><Link to={"/ui/apinvoice/" + id}>AP {id}</Link></div>
            })}
            <h1>AR Invoices</h1>
            {props.row.ar_ids.split(", ").map((id) => {
                return <div><Link to={"/ui/arinvoice/" + id}>AR {id}</Link></div>
            })}
        </div>
    }

    const exportData = () => {
        APP.central.ClientHealth.excelExport(period, endPeriod, filteredData).then((r) => {
            console.log(r.result)
        })
    }

    if (loading) {
        return <div>
            <Button onClick={() => setGroupBy("client")} style={{marginRight: "5px"}}>View by Client</Button> 
            <Button onClick={() => setGroupBy("site")} style={{marginRight: "5px"}}>View by Site</Button> 
            <Button onClick={() => setGroupBy("service")} style={{marginRight: "5px"}}>View by Service</Button>
            <Button onClick={() => exportData()}>Export Data</Button>
            <ActionBar title="Actions">
                <ReasonableFilter columns={[
                    {column: "customer_name", heading: "Client Name"}, 
                    {column: "site_name", heading: "Site Name"},
                    {column: "service_friendly_id", heading: "Service ID"},
                    {column: "environmental_industry", heading: "Industry"},
                    {column: "regional_account_manager", heading: "Manager"}       
                ]} dataset={data} setFilteredDataset={setFilteredData} />
                <Bound to={stateRef.current} onChange={updateRef}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <PeriodSelect field="startPeriod" name="Start Date" />
                    <PeriodSelect field="endPeriod" name="End Date" />
                </Bound>
            </ActionBar>
            <Loading/>
        </div>      
    } else if (data.length < 1) {
        return <div>
            <Button onClick={() => setGroupBy("client")} style={{marginRight: "5px"}}>View by Client</Button> 
            <Button onClick={() => setGroupBy("site")} style={{marginRight: "5px"}}>View by Site</Button> 
            <Button onClick={() => setGroupBy("service")} style={{marginRight: "5px"}}>View by Service</Button>
            <Button onClick={() => exportData()}>Export Data</Button>
            <ActionBar>
                <ReasonableFilter columns={[
                    {column: "customer_name", heading: "Client Name"}, 
                    {column: "site_name", heading: "Site Name"},
                    {column: "service_friendly_id", heading: "Service ID"},
                    {column: "environmental_industry", heading: "Industry"},
                    {column: "regional_account_manager", heading: "Manager"}       
                ]} dataset={data} setFilteredDataset={setFilteredData} />
                <Bound to={stateRef.current} onChange={updateRef}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <PeriodSelect field="startPeriod" name="Start Date" />
                    <PeriodSelect field="endPeriod" name="End Date" />
                </Bound>
            </ActionBar>
            No data for period.
        </div> 
    } else if (groupBy == "site") {
        return <div>
            <Button onClick={() => setGroupBy("client")} style={{marginRight: "5px"}}>View by Client</Button> 
            <Button onClick={() => setGroupBy("site")} style={{marginRight: "5px"}}>View by Site</Button> 
            <Button onClick={() => setGroupBy("service")} style={{marginRight: "5px"}}>View by Service</Button>
            <Button onClick={() => exportData()}>Export Data</Button>
            <ActionBar title="Actions">
                <ReasonableFilter columns={[
                    {column: "customer_name", heading: "Client Name"}, 
                    {column: "site_name", heading: "Site Name"},
                    {column: "service_friendly_id", heading: "Service ID"},
                    {column: "environmental_industry", heading: "Industry"},
                    {column: "regional_account_manager", heading: "Manager"}       
                ]} dataset={data} setFilteredDataset={setFilteredData} />
                <Bound to={stateRef.current} onChange={updateRef}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <PeriodSelect field="startPeriod" name="Start Date" />
                    <PeriodSelect field="endPeriod" name="End Date" />
                </Bound>
            </ActionBar>
            
            <div className="flex" style={{marginTop: "3em", marginBottom: "3em"}}>
                <LargeStatBlock value={entryTotal} label="Sites" icon="store"/>
                <LargeStatBlock value={arTotal} label="AR Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={apTotal} label="AP Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={marginTotal} label="Margin" icon="money-bill" symbol="$"/>
            </div>
            <Tabular data={filteredData} style={{minWidth: "100%"}}>
                <TabularColumn title="Customer" data={(row) => <Link to={"/ui/clients/" + row.customer_friendly_id}>{row.customer_name}</Link>} />
                <TabularColumn title="Industry" data={(row) => row.environmental_industry} />
                <TabularColumn title="Site" data={(row) => <Link to={"/ui/sites/detail/" + row.site_friendly_id}>{row.site_name}</Link>} />
                <TabularColumn title="Manager" data={(row) => row.regional_account_manager} />
                <TabularColumn title="Billing Period" data={(row) => row.billing_period} />
                <TabularColumn title="AR Total" data={(row) => <NiceCurrency roundedPlaces={0} onClick={(row) => openARLinks(row)}>{row.ar_total}</NiceCurrency>} />
                <TabularColumn title="AP Total" data={(row) => <NiceCurrency roundedPlaces={0} onClick={(row) => openAPLinks(row)}>{row.ap_total}</NiceCurrency>} />
                <TabularColumn title="Margin" data={(row) => <NiceCurrency roundedPlaces={0}>{row.margin}</NiceCurrency>} />
                <TabularColumn title="Margin %" data={(row) => <NicePercent places={0}>{row.margin_percentage}</NicePercent>} />
                <TabularColumn title="Invoices" data={(row) =>
                    <Icon onClick={() => openInvoiceLinks(row)} icon="file-invoice-dollar"/>
                }/>
                {/*<TabularColumn title="Expand" data={(row) => 
                    <Icon onClick={() => openDataModal(row)} icon="search"/>
                }/>*/}
            </Tabular>

        </div>
    } else if (groupBy == "client") {
        return <div>
            <Button onClick={() => setGroupBy("client")} style={{marginRight: "5px"}}>View by Client</Button> 
            <Button onClick={() => setGroupBy("site")} style={{marginRight: "5px"}}>View by Site</Button> 
            <Button onClick={() => setGroupBy("service")} style={{marginRight: "5px"}}>View by Service</Button>
            <Button onClick={() => exportData()}>Export Data</Button>
            <ActionBar title="Actions">
                <ReasonableFilter columns={[
                    {column: "customer_name", heading: "Client Name"}, 
                    {column: "site_name", heading: "Site Name"},
                    {column: "service_friendly_id", heading: "Service ID"},
                    {column: "environmental_industry", heading: "Industry"},
                    {column: "regional_account_manager", heading: "Manager"}       
                ]} dataset={data} setFilteredDataset={setFilteredData} />
                <Bound to={stateRef.current} onChange={updateRef}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <PeriodSelect field="startPeriod" name="Start Date" />
                    <PeriodSelect field="endPeriod" name="End Date" />
                </Bound>
            </ActionBar>
            
            <div className="flex" style={{marginTop: "3em", marginBottom: "3em"}}>
                <LargeStatBlock value={entryTotal} label="Clients" icon="store"/>
                <LargeStatBlock value={arTotal} label="AR Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={apTotal} label="AP Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={marginTotal} label="Margin" icon="money-bill" symbol="$"/>
            </div>
            <Tabular data={filteredData} style={{minWidth: "100%"}}>
                <TabularColumn title="Customer" data={(row) => <Link to={"/ui/clients/" + row.customer_friendly_id}>{row.customer_name}</Link>} />
                <TabularColumn title="Industry" data={(row) => row.environmental_industry} />
                <TabularColumn title="Manager" data={(row) => row.regional_account_manager} />
                <TabularColumn title="Billing Period" data={(row) => row.billing_period} />
                <TabularColumn title="AR Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ar_total}</NiceCurrency>} />
                <TabularColumn title="AP Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ap_total}</NiceCurrency>} />
                <TabularColumn title="Margin" data={(row) => <NiceCurrency roundedPlaces={0}>{row.margin}</NiceCurrency>} />
                <TabularColumn title="Margin %" data={(row) => <NicePercent places={0}>{row.margin_percentage}</NicePercent>} />
                <TabularColumn title="Invoices" data={(row) =>
                    <Icon onClick={() => openInvoiceLinks(row)} icon="file-invoice-dollar"/>
                }/>
                {/*<TabularColumn title="Expand" data={(row) => 
                    <Icon onClick={() => openDataModal(row)} icon="search"/>
                }/>*/}
            </Tabular>

        </div>
    }
    else {
        return <div>
            <Button onClick={() => setGroupBy("client")} style={{marginRight: "5px"}}>View by Client</Button> 
            <Button onClick={() => setGroupBy("site")} style={{marginRight: "5px"}}>View by Site</Button> 
            <Button onClick={() => setGroupBy("service")} style={{marginRight: "5px"}}>View by Service</Button>
            <Button onClick={() => exportData()}>Export Data</Button>
            <ActionBar title="Actions">
                <ReasonableFilter columns={[
                    {column: "customer_name", heading: "Client Name"}, 
                    {column: "site_name", heading: "Site Name"},
                    {column: "service_friendly_id", heading: "Service ID"},
                    {column: "environmental_industry", heading: "Industry"},
                    {column: "regional_account_manager", heading: "Manager"}       
                ]} dataset={data} setFilteredDataset={setFilteredData} />
                <Bound to={stateRef.current} onChange={updateRef}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <PeriodSelect field="startPeriod" name="Start Date" />
                    <PeriodSelect field="endPeriod" name="End Date" />
                </Bound>
            </ActionBar>
            
            <div className="flex" style={{marginTop: "3em", marginBottom: "3em"}}>
                <LargeStatBlock value={entryTotal} label="Services" icon="store"/>
                <LargeStatBlock value={arTotal} label="AR Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={apTotal} label="AP Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={marginTotal} label="Margin" icon="money-bill" symbol="$"/>
            </div>
            <Tabular data={filteredData} style={{minWidth: "100%"}}>
                <TabularColumn title="Customer" data={(row) => <Link to={"/ui/clients/" + row.customer_friendly_id}>{row.customer_name}</Link>} />
                <TabularColumn title="Industry" data={(row) => row.environmental_industry} />
                <TabularColumn title="Site" data={(row) => <Link to={"/ui/sites/detail/" + row.site_friendly_id}>{row.site_name}</Link>} />
                <TabularColumn title="Service" data={(row) => <Link to={"/ui/services/edit/" + row.service_id}>{row.service_friendly_id}</Link>} />
                <TabularColumn title="Manager" data={(row) => row.regional_account_manager} />
                <TabularColumn title="Billing Period" data={(row) => row.billing_period} />
                <TabularColumn title="AR Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ar_total}</NiceCurrency>} />
                <TabularColumn title="AP Total" data={(row) => <NiceCurrency roundedPlaces={0}>{row.ap_total}</NiceCurrency>} />
                <TabularColumn title="Margin" data={(row) => <NiceCurrency roundedPlaces={0}>{row.margin}</NiceCurrency>} />
                <TabularColumn title="Margin %" data={(row) => <NicePercent places={0}>{row.margin_percentage}</NicePercent>} />
                <TabularColumn title="Invoices" data={(row) =>
                    <Icon onClick={() => openInvoiceLinks(row)} icon="file-invoice-dollar"/>
                }/>
                {/*<TabularColumn title="Expand" data={(row) => 
                    <Icon onClick={() => openDataModal(row)} icon="search"/>
                }/>*/}
            </Tabular>

        </div>
    }
}

const LargeStatBlock = (props) => {
    let percent = null;
    if (props.units == "%") {
        percent = "%";
        props.units = null;
    }
    return <div className="stat-block-large" style={{width: "30%"}}>
        <div className="stat"><Icon icon={props.icon} fType={props.fType || "fal"} color={props.color} size="2x" /></div>
        <div className="stat-value"> {props.symbol} {Number(props.value).toLocaleString()} {percent} {props.units ? <span className="stat-units">{props.units}</span> : null}</div>
        <div className="stat-label">{props.label}</div>
    </div>
}

/*
<LargeStatBlock value={otherData.clientTotal} label="Clients" icon="user"/>
                <LargeStatBlock value={otherData.siteTotal} label="Sites" icon="store"/>
                <LargeStatBlock value={otherData.totalAR.toFixed(2)} label="AR Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={otherData.totalAP.toFixed(2)} label="AP Total" icon="money-bill" symbol="$"/>
                <LargeStatBlock value={otherData.totalMargin.toFixed(2)} label="Margin" icon="money-bill" symbol="$"/>
*/