import React, { useContext, useEffect, useRef, useState } from "react";
import { ActionBar, Button, Grid, Icon, InputAutoComplete, InputSelect, InputText, InputTextPattern, Loading, LookupInputSelect, SaveToolbar } from "@opidcore/components";
import { DataContext } from "@opidcore/components/Bound";
import BoundCommit from "@opidcore/components/BoundCommit";
import { GridContext, GridItem } from "@opidcore/components/Grid";
import { CurrentModal, RoutedDefaults } from "@opidcore/components/OpidApplication";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import _ from "lodash";

import { Link, useParams } from 'react-router-dom';
import { Bound, InputToggleSwitch } from "../../../../../OpidCore/public/js/components";
import { APP } from '../../../../js';
import Panel, { PanelGroup } from "../../../../js/components/Panel";
import { AddressBlock } from "../../components";
import ChangeLogs from "../ChangeLog/View";
import { NiceMaterial } from "../Nice.jsx";
import Notes from "../Notes";
import Tasks from "../Tasks";
import { NiceVisualServices, SiteContacts } from "./DetailSite";
import ValueAddPrograms from './ValueAddPrograms';
import FutureOpportunities from "../FutureOpportunities";
import EditClientContact from "../Customer/EditClientContact";

function Services(props) {
	return (
		<div>
			{props.services.map((s) => {
				return (
					<div key={s.id}>
						<h5>Service {s.name}</h5>
						<NiceMaterial>{s.material}</NiceMaterial>
					</div>
				);
			})}
		</div>
	);
}

function EditSiteEquipment(props) {
	let [siteRef, setSiteRef] = useState(props.site);

	useEffect(() => {
		setSiteRef(props.site);
	}, [props.site]);

	if (siteRef.id == undefined) {
		return <Loading />;
	}

	const handleCellAction = (e, whatWasClicked, gridCtx, sourceAction) => {
		let modelName = undefined;
		let defaults = {};
		let insertAt = gridCtx.sortedData.length;
		let currentBasis = undefined;

		if (whatWasClicked.boundAs == "siteEquipmentSensors") {
			modelName = "SiteEquipmentSensor";
			defaults = { siteEquipment: whatWasClicked.row, historical: 0, inventoryStatus: "New" };
			insertAt = whatWasClicked.rowIndex;
			currentBasis = "siteEquipmentSensors";
		} else {
			modelName = "SiteEquipment";
			defaults = { description: "New Equipment", historical: 0, site: siteRef };
		}

		APP.central.Grid.fetchGrid(modelName, "Create", { defaults: defaults }).then((r) => {
			if (r.result.rows[0] && r.result.rows[0][modelName]) {
				if (currentBasis != undefined) {
					gridCtx.pushSubGridData(r.result.rows[0][modelName], insertAt, currentBasis);
				} else {
					gridCtx.setData(r.result.rows[0][modelName], insertAt);
				}
			} else {
				console.log("something went wrong adding a lookup");
			}
		});
	}

	const gridProps = {
		key: "equipment_grid",
		gridIdentifier: "site_equipment",
		data: props.siteEquipmentData,
		rowBasis: "id",
		rowBasisGroup: "id",
		scroll: true,
		appendable: { text: "add a equipment", add: null },
		rowActions: [{ text: "Delete", action: (e, toBeBound, whatWasClicked, gridCtx, sourceAction) => { console.log("TODO"); } }],
		onCellAction: handleCellAction
	}

	const sensorGridProps = {
		boundData: "siteEquipmentSensors",
		rowBasis: "id",
		rowBasisGroup: "id",
		gridIdentifier: "sensors",
		appendable: { text: "add a sensor", add: null }
	}

	return <div key="edit_site_equipment">
		<h4>Equipment</h4>
		<Grid {...gridProps}>
			<GridItem title="Site"><LookupInputSelect disabled={true} what="site" field="site" showEmpty={true} /></GridItem>
			<GridItem title="Service"><LookupInputSelect what="service" field="service" fetch={{ site: siteRef.id }} showEmpty={true} /></GridItem>
			<GridItem title="Location"><InputText field="location" /></GridItem>
			<GridItem title="Owner"><LookupInputSelect what="lookup:Site Equipment Owner" field="ownedBy" showEmpty={true} /></GridItem>
			<GridItem title="Description"><InputText field="description" /></GridItem>
			<GridItem title="Notes"><InputText field="notes" /></GridItem>
			<GridItem key="sensors">
				<Grid {...sensorGridProps}>
					<GridItem title="Sensor #"><InputText field="sensorNumber" /></GridItem>
					<GridItem title="Type"><InputText field="sensorType" /></GridItem>
					<GridItem title="Owner"><LookupInputSelect what="lookup:Site Equipment Owner" field="ownedBy" showEmpty={true} /></GridItem>
					<GridItem title="Status"><InputText field="sensorStatus" /></GridItem>
					<GridItem title="Status"><InputText field="inventoryStatus" /></GridItem>
				</Grid>
			</GridItem>
		</Grid>
	</div>
}

export default function SiteEdit(props) {
	const navBar = useNavBar("Site Edit", []);
	const gridContext = useContext(GridContext);

	let params = useParams();
	const extra = useContext(RoutedDefaults);
	const [selectedProvince, setSelectedProvince] = useState("");
	const [siteId, setSiteId] = useState(props.id != undefined ? props.id : params.id);
	const [umbrellaAttributes, umbrellaAttributesDataSet] = useData("umbrella_attributes-site-" + params.id, APP.central.Customer.fetchUmbrellaAttributesForSite, { siteId: siteId });

    const currentModal = useContext(CurrentModal);

	let defaults = { ...extra };
	if (props.defaults != undefined) {
		defaults = { ...defaults, ...props.defaults };
	}

	const [data, dataSet] = useData("site", siteId, defaults);    
	const [services, setServices] = useState([]);
	let [siteEquipmentData, setSiteEquipmentData] = useState([]);
	//for setting primary customer contact
	const [contactOptions, setContactOptions] = useState([]);

	const [isUS, setIsUS] = useState(false);

	const [vendors, setVendors] = useState([]);
  const [vendorChildren, setVendorChildren] = useState([]);

  const [disableActiveToggle, setDisableActiveToggle] = useState(true)

  useEffect(() => {
    if(vendors.length > 0){
      const parentVendor = [];
      vendors.forEach((v) => {
        if(v.parentVendor == null){
          parentVendor.push(v);
        }
      });    

      if(parentVendor.length > 0){
        setVendorChildren(parentVendor[0].childLocations);
      }

    }
  }, [vendors]);

 useEffect(() => {
	const tempId = APP.getWebSession().userId;
	APP.central.Customer.allowSetInactive(siteId, tempId).then((r) => {
		setDisableActiveToggle(r.result)
	})
	}, [siteId]);

	useEffect(() => {
		if (siteId > 0) {
			APP.central.Site.fetchSiteEquipment(siteId).then((r) => {
				setSiteEquipmentData(r.result);
			});
		}
	}, []);


	const updateCountry = (a, b, c) => {
		if (c == "United States") {
			setIsUS(true);
		} else {
			setIsUS(false);
		}
	}


	const updateProvince = (a, b, c) => {		
		setSelectedProvince(c);
	}

	useEffect(() => {
		if (data && data.id != undefined) {
			APP.central.Service.fetchFilteredServicesForCustomer(data.customer, data.id, false).then((r) => {
				setServices(r.result);
				var serviceIds = []
				r.result.forEach((s) => {
					serviceIds.push(s.id)
				})
				APP.central.Vendor.fetchDataFromServices(serviceIds).then((r) => {
					setVendors(r.result.vendors)
				})
			});

			APP.central.Customer.fetchContacts(data.customer).then((r) => {
				const options = [];
				_.forEach(r.result, (contact) => {
					let value = "";
					(contact.lastName ? (value += contact.lastName + ", ") : "") +
						(contact.firstName ? (value += contact.firstName + ", ") : "") +
						(contact.email ? (value += contact.email) : "");
					const option = { key: contact.id, value: value };
					options.push(option);
				});
				setContactOptions(options);
			});

			const clientId = data.customer;
			const links = [<Link to={"/ui/clients/" + clientId}>Client</Link>, <Link to={"/ui/clients/sites/" + clientId}>Sites</Link>, 'Edit Site'];

			const pageTitle = <div className="title-with-stuff" outerTag="div">
				<h2>Edit Site #{data.friendlyId}</h2>

				<div className="flex-spacer" />
			</div>;

			const navbarUpdateOptions = navBar.createUpdateOptions("Edit Site #" + data.friendlyId, pageTitle, links, { selectedItem: 2 });

			navBar.fullUpdate(navbarUpdateOptions);
			updateCountry(null, null, data.country);
		}
	}, [dataSet.loading]);

	const isPostalCodeRightFormat = (postalCode) =>{
		let regexp = isUS ? "^([0-9][0-9][0-9][0-9][0-9])$": "^([A-Z][0-9][A-Z]) ?([0-9][A-Z][0-9])$" ;
		return postalCode.match(regexp) != null; 
	}

	//checks all requiured fields, returns false if any are empty
	const checkRequiredFields = (siteData) => {	 	

		if (_.isEmpty(siteData.to.name) || _.isEmpty(_.trim(siteData.to.name))) {
			APP.alert("Please enter a name for the site.");
			return false;
		}
		if (_.isEmpty(siteData.to.address) || _.isEmpty(_.trim(siteData.to.address))) {
			APP.alert("Please enter an address for the site.");
			return false;
		}
		if (_.isEmpty(siteData.to.city) || _.isEmpty(_.trim(siteData.to.city))) {
			APP.alert("Please enter a city for the site.");
			return false;
		}
		if (_.isEmpty(siteData.to.country) || _.isEmpty(_.trim(siteData.to.country))) {
			APP.alert("Please enter a country for the site.");
			return false;
		}
		if (_.isEmpty(siteData.to.province) || _.isEmpty(_.trim(siteData.to.province))) {
			APP.alert("Please enter a province/state for the site.");
			return false;
		}
		if (_.isEmpty(siteData.to.postalCode) || _.isEmpty(_.trim(siteData.to.postalCode)) || !isPostalCodeRightFormat(siteData.to.postalCode)) {
			APP.alert("Please enter a postal code with correct format for the site.");
			return false;
		}

		return true;
	}

	const doSave = () => {
		const siteData = APP.registeredBoundMagics["editSite" + siteId];

		if (siteData != undefined && checkRequiredFields(siteData)) {
			if (siteData.to.historical == true) {
				siteData.magicalSet("historical", 1);
			}
			if (siteData.to.historical == false) {
				siteData.magicalSet("historical", 0);
			}


      // update updated_by and created_by fields
      siteData.magicalSet("updatedBy", APP.getWebSession().userId);
      if (siteData.magicalGet("id") == 0 || siteData.magicalGet("id") == null) {
        siteData.magicalSet("createdBy", APP.getWebSession().userId);
      }


			return APP.central.Site.saveChanges(siteData.to.id, siteData.to).then((r) => {
				if (props.afterSave != undefined) {
					props.afterSave(r.result);

					return false;
				} else if (r != undefined && (siteId == 0 || siteId == null)) {
					APP.redirect(props.match.path.replace(":id", r.result.friendlyId));
          APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
				}
			});
		}

		if (gridContext.value != undefined) {
			const gridDeltas = gridContext.value.getAllBoundDeltas();
			if (gridDeltas.length > 0) {
				APP.central.Bound.saveChanges(gridDeltas).then((r) => {
					gridContext.value.resetAllBoundDeltas();
				});
			}
		}
	}

	const clickService = (service) => {
		APP.instance.createModal("/ui/services/edit/" + service.id, { modal_name: "Service #" + service.friendlyId }, {});
	}

	const navigateToEditContactList = () => {
		const siteData = APP.registeredBoundMagics["editSite" + siteId];
		const deltas = siteData.getAllDeltas();
		let id = data.customer;

		if(data.friendlyId != undefined){
			const splitId = data.friendlyId.split("-");
			if(splitId.length >= 2 && splitId[0] != undefined && splitId[1] != undefined){
				id = splitId[0] + "-" + splitId[1];
			}
		}
		if(id == undefined || id == 0 || id == "-"){
			APP.alert("Error. No Client found");
			return;
		}
		const path = "/ui/clients/" + id + "#contact-list";

		if(deltas.length > 0){
			APP.confirm("Unsaved changes. Continue without saving?", (ok) => {
				if(ok){
					window.open(path, '_blank').focus();
				}
			});
		} else {
			window.open(path, '_blank').focus();
		}
	}

	if (dataSet.loading == true) {
		return <Loading />;
	}

	return (
		<div key="edit_site" className="edit-site">
      <ActionBar>
        <Link to={"/ui/sites/detail/" + data.friendlyId}>
          <Icon icon="chevron-left" size="2x" title="Stop Editing"/>
        </Link>
        <div style={{ flex: 5 }}></div>
        <Notes 
            model={data} 
            entities={[data.customer]} 
            types={["Site"]}
          />
				<Tasks model={data} />
				<ChangeLogs model={data} />
      </ActionBar>

			<BoundCommit
				afterSave={props.afterSave}
				initialValue={data}
				dataSet={dataSet}
				forceSaveProps={_.keys(defaults)}
				commit={APP.central.Site.saveChanges}
				commitWithParent={true}
				boundId={"editSite" + siteId}
			>
				<PanelGroup style={{ display: "flex", flexDirection: "column" }}>
					<PanelGroup style={{ display: "flex", flexDirection: "row" }}>
						<Panel title={"General Info"}>
							<InputAutoComplete disabled={siteId > 0 ? true : false} store="string" field="customer" name="Client" central={APP.central.Customer.autocomplete}>
								{(customer) => <div className="customer_option">{customer.displayValue}</div>}
							</InputAutoComplete>
							<InputText required={true} field="name" name="Site Name" />
							<InputText field="sustainabilityName" name="Sustainbility Name" />
							<InputText field="billingName" name="Billing Name" />
							<Bound to={data}>
								<InputToggleSwitch
									field="historical"
									storeAs="int"
									opposite={true}
									name={(historical) => {
										return historical == undefined || historical == true ? "Inactive" : "Active";
									}}
									disabled={disableActiveToggle}
								/>
							</Bound>
							<AddressBlock mapFields={["address", "city", "province"]}>
								<InputText required={true} field="address" name="Site Address Line 1" />
								<InputText field="addressLine2" name="Site Address Line 2" />
								<InputText required={true} field="city" name="City" />
								<LookupInputSelect required={true} what="lookup:State Province" parameter1={isUS ? "USA" : "CAN"} onChange={(a, b, c) => updateProvince(a, b, c)} field="province" name={isUS ? "State" : "Province" } />
								{isUS ? (
									<InputTextPattern field="postalCode" name="Zip Code" message="54321" pattern={"^([0-9][0-9][0-9][0-9][0-9])$"} />
								) : (
									<InputTextPattern field="postalCode" name="Postal Code" message="A0A 1B1" pattern={"^([A-Z][0-9][A-Z]) ?([0-9][A-Z][0-9])$"} /> 
								)}
								<LookupInputSelect required={true} what="lookup:Country" name="Country" field="country" onChange={(a, b, c) => updateCountry(a, b, c)} />
							</AddressBlock>

							<h3>Site Attributes</h3>
							<TagEditor field="tags" umbrellaAttributes={umbrellaAttributes} />
						</Panel>
						<Panel title={<h3>Contacts <Icon icon="external-link" onClick={()=>navigateToEditContactList()} title="Edit From Client"/></h3>}>
							<SiteContacts siteFriendlyId={siteId} edit={true} site={data}/>
						</Panel>
					</PanelGroup>

					<Panel title={"Other Info"}> 
						{/*<InputSelect field="primaryCustomerContact" name="Primary Site Contact" options={contactOptions} showEmpty="true" emptyIsNull={true} />*/}
						<InputText field="purchaseOrder" name="Purchase Order" />
						<Tax theProvince={selectedProvince}/>

						<LookupInputSelect field="currency" what="lookup:Currency" name="Currency" showEmpty={false} forcedDefault={true} defaultValue={"CAD"} />
						<LookupInputSelect what="currency" field="invoiceCurrency" name="Invoice Currency" store="object" display="description"/>

						{/* <LookupInputSelect what="lookup" field="environmentalIndustry" name="Industry"  /> */}
                        <EnvironmentalIndustryOverride clientId={data.customer} />
						<EnvironmentalDensityOverride clientId={data.customer} />						

						{vendors.length == 0 ? null : <InputSelect field="vendorContactOverride" name="Vendor Contact Override" optionsCollection={vendorChildren} optionKey="id" labelKey="name" store="id" storeAs="int"/>}
            <FutureOpportunities model={data} />
            <ValueAddPrograms />
					</Panel>

				</PanelGroup>

				<SaveToolbar handleSave={() => doSave()} showCancel={true} onCancel={() => dataSet.fetch()} />
			</BoundCommit>

			<NiceVisualServices services={services} onClick={clickService} />

			{siteId > 0 && false ? <EditSiteEquipment site={data} siteEquipmentData={siteEquipmentData} /> : ""}
		</div>
	);
}

const EnvironmentalDensityOverride = ({ clientId }) => {
	const boundMagic = useContext(DataContext);

	const [usesClientDensity, setUsesClientDensity] = useState(false);
	const [client, clientDataSet] = useData("customer;" + clientId, clientId);

	useEffect(() => {
		if (client != undefined && clientDataSet.loading == false) {
			if (client.environmentalDensity != "Site Specific") {
				boundMagic.magicalSet("environmentalDensity", client.environmentalDensity);
				setUsesClientDensity(true);
			}
		}
	}, [clientDataSet.loading]);


	return <div key="environmental-density-override">
		<LookupInputSelect what="lookup" field="environmentalDensity" name={"Density Setting" + (usesClientDensity ? " (set at client level)" : "")} title={usesClientDensity ? "Set at client level" : null} filter={(lookup) => { return lookup.key != "Site Specific" }} disabled={usesClientDensity} />
	</div>
}

const EnvironmentalIndustryOverride = ({ clientId }) => {
	const boundMagic = useContext(DataContext);	
	const [client, clientDataSet] = useData("customer;" + clientId, clientId);

	useEffect(() => {
		if (client != undefined && clientDataSet.loading == false) {			
				boundMagic.magicalSet("environmentalIndustry", client.environmentalIndustry);			
			
		}
	}, [clientDataSet.loading]);
    
	return <div key="environmental-industry-override">
		<LookupInputSelect what="lookup" field="environmentalIndustry" name="Industry" />
	</div>
}

const Tax = ({theProvince}) => {	 
	const boundMagic = useContext(DataContext);
	const taxes = useRef([]);
	const [stateProvinceData, stateProvinceDataSet] = useData("stateProvinceLookup", APP.central.Lookup.fetchForName, { name: "State Province" });

	const getDefaultTax = (taxList, province) => {		
		taxes.current = taxList;

		// if (boundMagic.magicalGet("defaultTax", null) != null && boundMagic.magicalGet("defaultTax", null) != 0 && boundMagic.magicalGet("defaultTax", null) != "") {
		// 	return;
		// }

		if (stateProvinceData.length == 0) {
			return;
		}

		if (province == undefined) {
			province = boundMagic.magicalGet("province", null);
		}

		if (province == null) {
			return;
		}

		const provinceLookup = _.find(stateProvinceData, { key: province });
		if (provinceLookup == undefined) {
			return;
		}

		const defaultTax = _.find(taxList, { name: provinceLookup.parameter2 });

		if (defaultTax == undefined) {
			return;
		}

		boundMagic.magicalSet("defaultTax", defaultTax.id);
	};

	const updateTax = (province) => {
		if (province != null && taxes.current.length > 0) {
			getDefaultTax(taxes.current, province);
		}
	};


	useEffect(() => {
		boundMagic.magicalState("province", updateTax);
	}, [boundMagic]);

	return <LookupInputSelect field="defaultTax" what="taxes" name="Sales Tax" showEmpty={true} defaultValue={(taxList) => getDefaultTax(taxList, theProvince)} />
}


const TagEditor = ({ field, umbrellaAttributes }) => {
	const boundMagic = useContext(DataContext);
	const fieldBound = boundMagic.getBound(field);
	const umbrellaAttributeTypes = useRef({});

	useEffect(() => {
		_.forEach(umbrellaAttributes, (ua) => {
			umbrellaAttributeTypes.current[ua.attribute] = { type: ua.attributeType, options: ua.attributeOptions };
		});
	}, [umbrellaAttributes]);

	return <div>
		{_.map(_.keys(fieldBound), (f) => {
			if (f.indexOf("_") != 0) {
				if (umbrellaAttributeTypes.current[f] == undefined || umbrellaAttributeTypes.current[f].type == "text") {
					return <InputText field={field + "." + f} name={f} />
				} else {
					return <InputSelect field={field + "." + f} name={f} options={umbrellaAttributeTypes.current[f].options} />
				}
			}
		})}
	</div>
}