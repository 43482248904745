//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BoundComponent from './BoundComponent';

import { DataContext } from './Bound';

import * as _ from 'lodash';
import moment from 'moment';

export default class InputText extends BoundComponent {

  static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };
  
  static contextType = DataContext;

  constructor(props, context) {
	  super(props, context);
	 
	  this.state = {value: ""}
	  this.inputElement = React.createRef();
	  this.handleChange = this.handleChange.bind(this);
	  var updateFunc = props.update;
	  if (updateFunc == null){
		  updateFunc =  function(obj, field, value){
			  console.log("no update function inputtext.js")
		  }
	  }
	  this.debouncedUpdate = _.debounce(updateFunc, 50);
  }
  
  componentDidMount(){
	  if(this.props != undefined && this.props.value != undefined){
	    	this.setState({value: this.props.value});
	    	this.props.update(this.props.obj, this.props.field, this.props.value, this.context);
	    }
  }

  componentDidUpdate() { 
    if (document.activeElement === this.inputElement.current) { 
      if (this.inputElement.current.type != "number" && this.inputElement.current.type != "color") {  
        const a = this.inputElement.current;
        //if we don't have the whole thing selected
        if (a.selectionEnd - a.selectionStart != a.value.length) {
          this.inputElement.current.setSelectionRange(this.state.cursorPosition, this.state.cursorPosition);
        }
      }
    }
    if(this.props.parent && this.props.parent === "invoiceLine" && this.inputElement.current && 
       this.props.field && (this.props.field === "quantity" || this.props.field === "unitPrice" || this.props.field === "vendorTaxTotal" || this.props.field === "vendorTotal") 
           && this.inputElement.current.value == ""){
      this.context.magicalSet(this.props.field, "");
    }
  }

  asetValue(value){
	  this.context.magicalSet(this.props.field, value); 
      this.setState({ value: "" + value });
      this.props.update('hey');
  }

  hasNonDigit(str) {
    let strHasNonDigit = /\D/.test(str); 
    return strHasNonDigit;     
  }
  onlyInteger(str){
    let intOnly = /^[0-9\-]+$/.test(str); 
    return intOnly;   
  } 
  onlyNumber(str){
    let numOrDot2 = /^[0-9\.\-]+$/.test(str); 
    return numOrDot2; 
  }
  
  //handleKeyup(e) {
    // if(e.key === 'Delete'){
    //   if(this.props.parent && this.props.parent ==="invoiceLine" 
    //   && (this.props.field === "unitPrice" || this.props.field === "quantity")){
    //     var entered = e.target.value;
    //     if(entered === ""){
    //       this.context.magicalSet(this.props.field, "");
    //     }        
    //   } 
    // }
  //}
  
  handleChange(e) { 
	  this.setState({cursorPosition: this.inputElement.current.selectionStart})

    var entered = e.target.value;    
    if (this.props.inputType === "decimal" && this.props.parent && this.props.parent ==="invoiceLine"){ 
      if(this.props.field === "unitPrice" || this.props.field === "quantity" || this.props.field === "vendorTaxTotal" || this.props.field === "vendorTotal"){       
        if(entered !== "" && ! this.onlyNumber(entered)){ 
           return;
        } 
       }      
    }	
	  
	  var newValue = (e.target.value == undefined || e.target.value == null)? "" : e.target.value;
	  var valueToSet = newValue;
    if (! (this.props.inputType === "decimal" && this.props.parent && this.props.parent ==="invoiceLine" 
                && (this.props.field === "unitPrice" || this.props.field === "quantity" || this.props.field === "vendorTaxTotal" || this.props.field === "vendorTotal"))){
        if (this.props.formatter){
          valueToSet = this.props.formatter.parse(valueToSet, this.props.curForm);
        }
    }
	  
	  if (this.props.inputType == "number"){
		  newValue = parseInt(newValue);
	  }
	  
	  if (this.props.inputType == "decimal"){
		  //attempt to parse a float if we can.
		  var float = newValue.match(/^[0-9\-\.]*$/) != null ? parseFloat(newValue) : null;
		  
		  if (isNaN(float)){
			  float = null;
		  }
		  
		  this.setState({tempValue: newValue});
		  this.lastFloat = float;
		  this.onBlur = true;

      if(this.props.inputType === "decimal" && this.props.parent && this.props.parent ==="invoiceLine" && 
                 (this.props.field === "unitPrice" || this.props.field === "quantity" 
                 || this.props.field === "vendorTaxTotal" || this.props.field === "vendorTotal") 
                      && valueToSet === ""){
                  this.setValue(valueToSet);
      }  
      else if (valueToSet != null && valueToSet > ""){
        this.setValue(valueToSet);
      }

		  return;
	  }

    if(this.props.doBlur == true){
      this.onBlur = true;
    }
	  

	  this.setValue(newValue);
	  this.onBlur = false;
	  
  }

  handleBlur(e) {
	  if (this.onBlur && this.state.tempValue && parseFloat(this.state.tempValue) == this.lastFloat){
	  	this.setValue(this.state.tempValue);
	  }
  }
  
  setValue(valueToSet){
	  
	  if (this.props.saveZeros == false && valueToSet == 0 ){
		 this.context.magicalSet(this.props.field, null);
	  }else{
      
	  	this.context.magicalSet(this.props.field, valueToSet);
  	  }
	  
      this.debouncedUpdate(this.props.obj, this.props.field, valueToSet, this.context);
      
      this.setState({tempValue: null});
  }

  

  getLastValue(){
	  return this.lastInputValue;
  }

  gotAValue(value){
    if(this.state.value != value){
      this.setState({value: value});
    }
  }
  
  render() {  
    var name = this.props.name;
    var inputType = "text";
    var theMaxLength = this.props.maxlength ? this.props.maxlength : null;
    
    if(this.listeningForChanges != this.context && this.context.magicalState){
      this.listeningForChanges = this.context;
      this.context.magicalState(this.props.field, (value)=>this.gotAValue(value));
    }
    
    var extraProps = {};
    
    if (this.props.tabIndex){
    	extraProps.tabIndex = this.props.tabIndex;
    }
    
    if (this.props.onBlur){
    	extraProps.onBlur = this.props.onBlur;
    }
    if (this.props.style){
    	extraProps.style = this.props.style;
    }
    if (this.props.onFocus){
    	extraProps.onFocus = (e) => {
        this.props.onFocus(this.context, e)
      }
    }
    if(this.props.title){
      extraProps.title = this.props.title;
    }
    
    
    if( this.props.field === "disabled_passCrypt" || this.props.field === "disabled_confirmPassCrypt" ){
    	inputType = "password";
    } else if( this.props.inputType != undefined ){
    	if(this.props.inputType == "decimal"){
    		inputType = "number";
    	} else {
    		inputType = this.props.inputType;
    	}
    }
    
    let inputValue = this.state.tempValue != null ? this.state.tempValue  : this.context.magicalGet(this.props.field); //this.state.value || "";
    
    if (inputValue == undefined){
    	inputValue = "";
    }
    
    if((this.state.value != undefined && this.state.value == "N/A") || (this.props.obj != undefined && this.props.obj.question_answer == "N/A")){
    //	inputValue = "N/A"; 
    }
    if (this.props.formatter){
       inputType = "text";
    }
    if ( ! (this.props.parent && this.props.parent === "invoiceLine" && inputValue === "") && this.props.formatter){ 
          inputValue = this.props.formatter.format(inputValue, this.props.curForm);  
    }
    
    if((inputValue == undefined || (typeof inputValue != "number" && inputValue.trim && inputValue.trim().length <= 0)) && this.props.defaultValue != undefined && this.props.defaultValue.trim().length > 0){
    	inputValue = this.props.defaultValue;
    }
    this.lastInputValue = inputValue;

    // if user is still typing keep their value
    if( ! (this.props.parent && this.props.parent === "invoiceLine" && this.inputElement.current && this.inputElement.current.value && 
                                                 this.props.field && (this.props.field === "quantity" || this.props.field === "unitPrice" 
                                                 || this.props.field === "vendorTaxTotal" || this.props.field === "vendorTotal") 
                                                 && ! this.onlyNumber(this.inputElement.current.value))
                                                 && this.inputElement.current == document.activeElement){   
           inputValue = this.inputElement.current.value;
    }

    var inputHTML = "";
    if(this.props.mode == "view"){
    	//return some span
    }

    if(this.props.readOnly != undefined && this.props.readOnly == true){
      return <div className="readOnly-field" style={this.props.style}>{inputValue}</div>;
    }

    let disabled = false;
    if(this.props.disabled){
		  if(typeof this.props.disabled == "function"){
			  disabled = this.props.disabled(this.context);
		  } else {
       	disabled = this.props.disabled;
      }    	
    } else if(this.props.disabledText){
      	disabled = this.props.disabledText;
    }else if (this.context.isReadOnly()){
      disabled = true;
    }
    
    
    if(inputType == "textarea"){
    	inputHTML = <textarea
        className={"form-control"}
        placeholder={this.props.placeholder || ""}
        value={inputValue}
        onChange={this.handleChange} 
    	  ref={this.inputElement}
        rows={this.props.rows || 3}
      	disabled={disabled? "disabled" : ""}
        maxlength={theMaxLength}/>;
    }else{
    	inputHTML = <input
    	{...extraProps}
        type={inputType}
        className={"form-control"}
        placeholder={this.props.placeholder || ""}
        value={inputValue}
        onChange={this.props.handleChange ? this.props.handleChange : this.handleChange}
        onKeyUp={this.props.onKeyUp ? this.props.onKeyUp : null}
        onClick={(e)=>e.stopPropagation()}
        onBlur={(e)=>{this.handleBlur(e)}}
    	  ref={this.inputElement}
      	disabled={disabled? "disabled" : ""} 
        maxlength={theMaxLength}/>;
    }    
    
    let label = "";
    if(this.props.hideLabel == true){
    	//do nothing
    } else if(name && inputType != "hidden"){
    	label = <label>{name} {(this.props.labelExtra || this.props.children) ? this.props.children : ''} {this.renderLabelTags()}</label>;
    }
       
    let className = this.props.className;
  
    if (this.state.hasError){
      className += " error";
    }

    const renderTheDiv = ()=>{  
      let isEmpty = !inputValue || (inputValue && inputValue.length == 0);  
        return(
          <div className={"field " +  className   + (this.props.format ? " format-" + this.props.format : "")} style={this.props.style}>
             { label }
             <span style={this.props.invReqField && isEmpty ? {border: "1px solid red", borderRadius: "0.3rem"} : null}> {inputHTML}</span>
          </div>)
      }

    return(renderTheDiv());     
  }
}