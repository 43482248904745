import React, { useContext, Fragment, useState, useEffect } from "react";
import { useData } from "@opidcore/hooks/WTF";
import BoundCommit from "@opidcore/components/BoundCommit";
import {
  InputText,
  DatePicker,
  UnlockToEdit,
  Icon,
  TagPicker,
  BoundView,
  Bound,
  InputSelect,
  InputCheckbox,
  Button,
  FlexRow, useMagic, Loading,
  InputDecimal
} from "@opidcore/components";
import { TermPicker, EndDatePicker } from "@opidcore/components/DatePicker";
import ActionBar from "@opidcore/components/ActionBar";
import { ContractStatusLight } from "../StatusLight";
import { File, NiceBox, NiceBoxContainer, LinesThing } from "../../components";
import { useParams } from "react-router-dom";
import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import Notes from "../Notes";
import Tasks from "../Tasks";
import _ from "lodash";
import { NiceBoxSection } from "../../components/NiceBox";
import ChangeLogs from "../ChangeLog/View";

export default function ClientEditContract(props) {
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const [data, dataSet] = useData("client_contract", params.id, extra);

  const addSites = (sites) => {
    APP.central.ClientContract.addTo(data.id, sites).then((r) => {
      //hopefully this can be smarter, or something?
      APP.central.Util.clearCache(dataSet.uid).then((r) => {
        dataSet.fetch();
      });
    });
  };

  const refreshDataset = () => {
    APP.central.Util.clearCache(dataSet.uid).then((r) => {
      dataSet.fetch();
    });
  };

  const openEditContract = () => {
    APP.instance.createModal(<EditClientContractDetails data={data} dataSet={dataSet} afterSave={refreshDataset} />, {
      modal_name: "Client Contract #" + params.id,
      className: "task-note-modal",
    });
  };

  const deleteContract = (id) => {
    APP.confirm("Are you sure?", (res) => {
      if (res == true) {
        APP.alert("Not yet implemented");
      }
    });
  };

  return (
    <div key="edit_client_contract">
      <h2 style={{ flex: "auto" }}> Client Contract</h2>
      <ActionBar model={data} showNotes={true} showTasks={true}>
        <ContractStatusLight contract={data} />
        <div style={{ flex: "auto" }} />
        {data.__type ? <Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} /> : <Loading/>}
        <TagPicker />
        <Notes model={data} />
        <Tasks model={data} />
        <ChangeLogs model={data}/>
      </ActionBar>

      {data.__type ? <DisplayClientContract clientContract={data} /> : <Loading/>}

      <h2>Contract Lines</h2>
      <LinesThing lines={data.lines} groups={["site", "service"]} dataSet={dataSet} onDoStuff={(sites) => addSites(sites)} checkService={undefined} />
      {data.lines && data.lines.length && data.lines.length > 0 ? null : <Button onClick={() => deleteContract(data.id)}>Delete Contract</Button>}
    </div>
  );
}

const EditClientContractDetails = ({ data, dataSet, afterSave = undefined }) => {
  const [lookups, lookupsDS] = useData("lookup;clientContractExpiryStatus", { name: "clientContractExpiryStatus" });

  const isLost = (expStatus) => {
    return expStatus == "lost";
  };

  const isCustom = (priceIncreaseAllowed) => {
    return priceIncreaseAllowed == "custom";
  };
  
  const [showTermDate, setShowTermDate] = useState(isLost(data.expiryStatus));
  const [showPriceIncreasePercentageMax, setShowPriceIncreasePercentageMax] = useState(isCustom(data.priceIncreaseAllowed));

  const [contract, setContract] = useState(data);
  const [expiryOpts, setExpiryOpts] = useState({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month to Month" , autoRenewal: "Auto Renewal"});
  const [contractEarlyTerminationClauseOpts, setContractEarlyTerminationClauseOpts] = useState({ none: "None", 30: "30 Days", 60: "60 Days" , 90: "90 Days", other: "Other"});
  const [priceIncreaseAllowedOpts, setPriceIncreaseAllowedOpts] = useState({ none: "None", custom: "Custom", cpi: "CPI" , open: "Open"});


  const bound = useMagic({});

  useEffect( ()=>{
    bound.magicalState("expiryStatus", (s)=>{
      setShowTermDate(isLost(s));
    });

    bound.magicalState("priceIncreaseAllowed", (pi)=>{
      setShowPriceIncreasePercentageMax(isCustom(pi));
    });
  }, [bound]);

  useEffect(() => {
    updateExpiryOpts();
  }, [lookupsDS.loading]);

  useEffect(() => {
    updateExpiryOpts();
  }, [contract]);

  useEffect(() => {
    setContract(data);
    bound.replaceTo(data);
  }, [data]);

  const doAfterSave = () => {
    APP.central.ClientContract.fetch(contract.id).then((r) => {
      if (r.status == "ok") {
        setContract(r.result);
        bound.replaceTo(r.result);
      }
      if (afterSave) {
        afterSave();
      }
    });
  }; 


  const updateExpiryOpts = () => {
    if (lookups && lookups.length > 0 && bound.magicalGet("expiryStatus")) {
      const statusOpt = _.find(lookups, { key: bound.magicalGet("expiryStatus") });
      if (statusOpt && statusOpt.key) {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month", autoRenewal : "Auto Renewal" , [statusOpt.key]: statusOpt.label});
      } else {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month" , autoRenewal : "Auto Renewal"});
      }
    }
  };

  return (
    <Fragment>
      <div>
        <FlexRow>
          <h2>Client Contract - {contract && contract.customer ? contract.customer.name + " (" + contract.customer.friendlyId + ")" : null}</h2>
        </FlexRow>
        <ContractStatusLight contract={contract} />
      </div>
      <BoundCommit to={bound} dataSet={dataSet} commit={APP.central.ClientContract.saveChanges} afterSave={doAfterSave} boundId={"editClientContractDetailsBound"}>
        <UnlockToEdit>
          <InputText field="friendlyId" name="Client Contract ID" />
        </UnlockToEdit>
        {showTermDate ? (
          <Fragment>
            <DatePicker field="terminationDate" name="Termination Date" required /> <InputText field="terminationNote" name="Termination Note" inputType="textarea" required />
          </Fragment>
        ) : null}
        <br></br>
        <DatePicker field="effectiveFrom" name="Contract Start Date" required={true} />
        <br></br>
        <TermPicker field="contractTerm" startField="effectiveFrom" endField="effectiveTo" required={true} />
        <br></br>
        <EndDatePicker termField="contractTerm" startField="effectiveFrom" field="effectiveTo" name="Contract End Date" required={true} />
        <br></br>
        <InputText
          field="expiryPeriod"
          inputType="number"
          name="Expiry Warning Period (days)"
          children={
            <span title={"Enter the number of days before the contract end date that you wish to be notified of the contract expiry. An email will be automatically sent to the account manager on this date"}>
              <Icon icon="info" color={"Grey"} size={"1x"} />
            </span>
          }
          title={"Enter the number of days before the contract end date that you wish to be notified of the contract expiry. An email will be automatically sent to the account manager on this date"}
        />
        <br></br>
        <InputSelect options={expiryOpts} name="Contract Term Status" field="expiryStatus" showEmpty={true} onChange={() => updateExpiryOpts()} />
        <br></br>

        <InputSelect options={contractEarlyTerminationClauseOpts} name="Early Termination Clause" field="earlyTerminationClause" showEmpty={true} />
        <br></br>
        <div className="flex-row" style={{ gap: "40px", justifyContent: "flex-start" }}>
          <div>
            <InputCheckbox field="autoRenewal" name="Auto Renewal" />
          </div>
        </div>
        <InputSelect field="priceIncreaseAllowed" name="PI Allowed" style={{ width: "50%" }} options={priceIncreaseAllowedOpts} showEmpty={false} />

        {showPriceIncreasePercentageMax ? (
          <Fragment>
            <InputDecimal
              field="priceIncreasePercentageMax"
              name="PI % max allowed"
              children={
                <span title={"Set the maximum percentage increase allowed for the contract price."}>
                  <Icon icon="info" color={"Grey"} size={"1x"} />
                </span>
              }
              title={"Set the maximum percentage increase allowed for the contract price."}
            />
            <DatePicker field="piAnniversaryDate" name="PI Anniversary Date" />
          </Fragment>
        ) : null}
      </BoundCommit>
    </Fragment>
  ); 
};

export { EditClientContractDetails };

export function DisplayClientContract(props) {
  const [files, setFiles] = useState([]);

  if (props.clientContract == undefined) {
    return null;
  }

  const openAnnotations = (md5) => {
    APP.alert("hey " + md5);
  };

  return (
    <div className="edit-client-contracts">
      <Bound to={props.clientContract} boundId="clientContractNiceBlocks" disableEditing={true}>
        <NiceBoxSection>
          <NiceBoxContainer>
            <h4>Client Info</h4>
            <NiceBox label="Client #">
              <BoundView boundTo="customer" key="customer-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Client">
              <BoundView boundTo="customer" key="customer-name">
                <InputText field="name" disabled={true} />
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>

          <NiceBoxContainer>
            <h4>Contract Info</h4>
            <NiceBox label="Client Contract #">
              <BoundView key="contract-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Start">
              <BoundView key="contract-effectiveFrom">
                <DatePicker field="effectiveFrom" />
              </BoundView>
            </NiceBox>

            <NiceBox label="End">
              <BoundView key="contract-effectiveTo">
                <DatePicker field="effectiveTo" />
              </BoundView>
            </NiceBox> 

            <NiceBox label="Early Termintation"> 
              <BoundView key="contract-earlyTermination">
                {(props.clientContract && props.clientContract.earlyTerminationClause) ? <span>{props.clientContract.earlyTerminationClause}</span> : <span>Unset</span>}
              </BoundView>
            </NiceBox>  

             <NiceBox label="Auto Renewal">
               <div> 
                {(props.clientContract && props.clientContract.autoRenewal) ? <div className="center">Yes</div> : <div className="center">No</div>}                    
              </div>
            </NiceBox>          
           

          </NiceBoxContainer>
        </NiceBoxSection>

        <File onFileList={(files) => setFiles(files)} showHistoricalSet={true} /> 
      </Bound>
    </div>
  );
}

