import React, { Fragment, useEffect, useState } from "react";
import Icon from "@opidcore/components/Icon";
import _ from "lodash";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import Loading from "@opidcore/components/Loading";
import Notes from "././Notes";

/*
  ex: <FutureOpportunities model={site} />
 
  "model" is the current model being viewed, it must have a __type and an id.
*/
export default function FutureOpportunities({ disabled = false, model = undefined }) {
  if (model && model.__type && model.id) {
    const relatedTable = model.__type;
    const relatedId = model.id;

    const [loading, setLoading] = useState(false);

    const [futureOpportunities, setFutureOpportunities] = useState([]);

    const [futureOpportunitiesOptions, setFutureOpportunitiesOptions] = useState([]);
    const [futureOpportunityTypes, setFutureOpportunityTypes] = useState([]);

    useEffect(() => {
      setLoading(true);
      APP.central.FutureOpportunity.fetchFutureOpportunities(relatedTable, relatedId).then((fetchFutureOpportunities) => {
        setFutureOpportunities(fetchFutureOpportunities.result);
      });

      APP.central.FutureOpportunity.fetchAvailablFutureOpportunityTypes().then((fetchAvailablFutureOpportunityTypes) => {
        setFutureOpportunityTypes(fetchAvailablFutureOpportunityTypes.result);
        //make these into options
        let options = fetchAvailablFutureOpportunityTypes.result.map((item) => {
          return { value: item.key, label: item.label };
        });
        setLoading(false);
        setFutureOpportunitiesOptions(options);
      });
    }, []);

    const handleChange = (selected, additionalInfo) => {
      setLoading(true);

      const newFutureOpportunity = {
        id: 0,
        opportunityType: selected.value,
        relatedId: relatedId,
        relatedTable: relatedTable,
        __type: "FutureOpportunity",
      };

      APP.central.FutureOpportunity.saveChanges(newFutureOpportunity.id, newFutureOpportunity).then((saveChanges) => {
        setFutureOpportunities([...futureOpportunities, saveChanges.result]);
        setLoading(false);
      });
    };

    // Custom styles for dropdowns
    const animatedComponents = makeAnimated();
    const customStyles = {
      input: (provided, state) => ({
        ...provided,
        height: 25,
        display: "flex",
        alignItems: "center",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginTop: 2,
      }),
    };

    return (
      <div className="opportunities">
        <h3>Future Opportunities</h3>
        <div>
          <div className="opportunity-select">
            {loading ? (
              <Loading />
            ) : disabled ? null : (
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={futureOpportunitiesOptions}
                key={`my_unique_select_key__${[]}`}
                onChange={handleChange}
                menuPosition={"fixed"}
                styles={customStyles}
                placeholder="Add Future Opportunity"
                blurInputOnSelect={true}
              />
            )}
          </div>

          <div className="opportunity-container">
            {futureOpportunities.map((opportunity, index) => {
              return (
                <FutureOpportunity
                  key={index}
                  opportunity={opportunity}
                  futureOpportunityTypes={futureOpportunityTypes}
                  futureOpportunities={futureOpportunities}
                  setFutureOpportunities={setFutureOpportunities}
                  disabled={disabled}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  } else return null;
}

const FutureOpportunity = ({ opportunity, futureOpportunityTypes, futureOpportunities, setFutureOpportunities, disabled }) => {

  // Get the opportunity type
  const opportunityType = _.find(futureOpportunityTypes, { key: opportunity.opportunityType });

  return (
    <Fragment>
      <div className="opportunity">
        <div className="opportunity-label">
          <div className="opportunity-label-icon">{opportunityType && opportunityType.parameter1 ? <Icon icon={opportunityType.parameter2} /> : null}</div>
          <div className="opportunity-label-text">{opportunityType.label}</div>
          <div className="opportunity-label-delete">
            {disabled ? null : (
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  APP.central.FutureOpportunity.deleteOpportunity(opportunity.id).then((r) => {
                    if (r.result == "deleted") {
                      setFutureOpportunities(_.filter(futureOpportunities, (o) => o.id != opportunity.id));
                    }
                  });
                }}
                icon="trash"
                title="delete Future Opportunity"
                size="1x"
              />
            )}
          </div>
        </div>
        <div className="opportunity-description">{opportunityType && opportunityType.parameter1 ? opportunityType.parameter1 : "missing description"}</div>

        <div className="opportunity-actions">
          <div className="opportunity-action">
            <Notes model={opportunity} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
