import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Bound,
  BoundView,
  Button,
  DatePicker,
  FlexRow,
  Icon,
  InputSelect,
  InputText,
  InputToggleSwitch,
  Loading,
  TagPicker,
  UnlockToEdit,
  useMagic
} from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import BoundCommit from "@opidcore/components/BoundCommit";
import { EndDatePicker, TermPicker } from "@opidcore/components/DatePicker";
import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import { useData } from "@opidcore/hooks/WTF";
import _ from "lodash";

import { useParams } from "react-router-dom";
import { File, LinesThing, NiceBox, NiceBoxContainer } from "../../components";
import { NiceBoxSection } from "../../components/NiceBox";
import ChangeLogs from "../ChangeLog/View";
import Notes from "../Notes";
import { ContractStatusLight } from "../StatusLight";
import Tasks from "../Tasks";

export default function VendorEdit(props) {
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const [data, dataSet] = useData("vendor_contract", params.id, extra);

  const refreshDataset = () => {
    APP.central.Util.clearCache(dataSet.uid).then((r) => {
      dataSet.fetch();
    });
  };

  const openEditContract = () => {
    APP.instance.createModal(<EditVendorContractDetails data={data} dataSet={dataSet} afterSave={refreshDataset} />, {
      modal_name: "Vendor Contract #" + params.id,
      className: "task-note-modal",
    });
  };

  const deleteContract = (id) => {
    APP.confirm("Are you sure?", (res) => {
      if (res == true) {
        APP.alert("Not yet implemented");
      }
    });
  };

  return (
    <div key="edit_vendor_contract">
      <h2 style={{ flex: "auto" }}> Vendor Contract</h2>
      <ActionBar model={data} showNotes={true} showTasks={true}>
        <ContractStatusLight contract={data} />
        <div style={{ flex: "auto" }} />
        {data.__type ? <Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} /> : <Loading/>}
        {/*<Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} />*/}
        <TagPicker />
        <Notes model={data} />
        <Tasks model={data} />
        <ChangeLogs model={data}/>
      </ActionBar>

      {data.__type ? <DisplayVendorContract vendorContract={data} /> : <Loading/>}

      <h2>Contract Lines</h2>
      <LinesThing lines={data.lines} groups={["client", "site", "service"]} />
      {data.lines && data.lines.length && data.lines.length > 0 ? null : <Button onClick={() => deleteContract(data.id)}>Delete Contract</Button>}
    </div>
  );
}

const EditVendorContractDetails = ({ data, dataSet, afterSave = undefined }) => {
  const [expiryLookups, expiryLookupsDS] = useData("lookup;vendorContractExpiryStatus", { name: "vendorContractExpiryStatus" });
  const [haulerNoticeLookups, haulerNoticeLookupsDS] = useData("lookup;haulerNoticePeriod", { name: "haulerNoticePeriod" });
  const isLost = (expStatus) => {
    return expStatus == "lost";
  };

  const [showTermDate, setShowTermDate] = useState(isLost(data.expiryStatus));
  const [contract, setContract] = useState(data);
  const [expiryOpts, setExpiryOpts] = useState({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month to Month" });
  const [haulerNoticeOpts, setHaulerNoticeOpts] = useState({
    30: "30 Days",
    60: "60 Days",
    90: "90 Days",
    120: "120 Days",
    180: "180 Days",
    noAutoRenewal: "No Auto Renewal",
    noContract: "No Contract",
    "30dayOut": "30 Day Out",
  });
  const [contractEarlyTerminationClauseOpts, setContractEarlyTerminationClauseOpts] = useState({ none: "None", 30: "30 Days", 60: "60 Days" , 90: "90 Days", other: "Other"});

  
  const bound = useMagic({});

  useEffect( ()=>{
    bound.magicalState("expiryStatus", (s)=>{
      setShowTermDate(isLost(s));
    } );
    
  }, [bound]);

  useEffect(() => {
    updateOpts();
  }, [expiryLookupsDS.loading, haulerNoticeLookupsDS.loading]);

  useEffect(() => {
    updateOpts();
  }, [contract]);

  useEffect(() => {
    setContract(data);
    bound.replaceTo(data);
  }, [data]);

  const doAfterSave = () => {
    APP.central.VendorContract.fetch(contract.id).then((r) => {
      if (r.status == "ok") {
        setContract(r.result);
        bound.replaceTo(r.result);
      }
      if (afterSave) {
        afterSave();
      }
    });
  };

  useEffect(() => {
    doAfterSave();
  }, []);

  const updateOpts = () => {
    if (expiryLookups && expiryLookups.length > 0 && bound.magicalGet("expiryStatus")) {
      const statusOpt = _.find(expiryLookups, { key: bound.magicalGet("expiryStatus") });
      if (statusOpt && statusOpt.key) {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month", [statusOpt.key]: statusOpt.label });
      } else {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month" });
      }
    }
    
    if (haulerNoticeLookups && haulerNoticeLookups.length > 0 && bound.magicalGet("hauler_notice_period")) {
      const haulerNoticeOpt = _.find(haulerNoticeLookups, { key: bound.magicalGet("hauler_notice_period") });
      if (haulerNoticeOpt && haulerNoticeOpt.key) {
        setHaulerNoticeOpts({
          30: "30 Days",
          60: "60 Days",
          90: "90 Days",
          120: "120 Days",
          180: "180 Days",
          noAutoRenewal: "No Auto Renewal",
          noContract: "No Contract",
          "30dayOut": "30 Day Out",
          [haulerNoticeOpt.key]: haulerNoticeOpt.label,
        });
      } else {
        setHaulerNoticeOpts({
          30: "30 Days",
          60: "60 Days",
          90: "90 Days",
          120: "120 Days",
          180: "180 Days",
          noAutoRenewal: "No Auto Renewal",
          noContract: "No Contract",
          "30dayOut": "30 Day Out",
        });
      }
    }
  };

  return (
    <Fragment>
      <div>
        <FlexRow>
          <h2>Vendor Contract - {contract && contract.niceName ? contract.niceName : null}</h2>
        </FlexRow>
        <ContractStatusLight contract={contract} />
      </div>
      <BoundCommit
        to={bound}
        dataSet={dataSet}
        commit={APP.central.VendorContract.saveChanges}
        afterSave={doAfterSave}
        boundId={"editVendorContractDetailsBound"}
      >
        <UnlockToEdit>
          <InputText field="friendlyId" name="Client Contract ID`" />
        </UnlockToEdit>
        {showTermDate ? (
          <Fragment>
            <DatePicker field="terminationDate" name="Termination Date" required />{" "}
            <InputText field="terminationNote" name="Termination Note" inputType="textarea" required />
          </Fragment>
        ) : null}
        <DatePicker field="effectiveFrom" name="Contract Start Date" required={true} />
        <TermPicker field="contractTerm" startField="effectiveFrom" endField="effectiveTo" required={true} />
        <EndDatePicker termField="contractTerm" startField="effectiveFrom" field="effectiveTo" name="Contract End Date" required={true} />
        <InputText field="expiryPeriod" inputType="number"  name="Expiry Warning Period (days)" children={<span 
          title={"Enter the number of days before the contract end date that you wish to be notified of the contract expiry. An email will be automatically sent to the account manager on this date"}
          ><Icon icon="info" style={{cursor: 'pointer'}} color={"Grey"} size={"1x"}/></span>}
          title={"Enter the number of days before the contract end date that you wish to be notified of the contract expiry. An email will be automatically sent to the account manager on this date"}
        />  
        <InputSelect options={expiryOpts} name="Contract Term Status" field="expiryStatus" showEmpty={true} onChange={() => updateOpts()}  />
        <InputSelect options={contractEarlyTerminationClauseOpts} name="Early Termination Clause" field="earlyTerminationClause" showEmpty={true} />
        <InputSelect options={haulerNoticeOpts} name="Hauler Notice Period" field="haulerNoticePeriod" defaultValue="30dayOut"/>
        
        <InputText field="externalVendorContractNumber" name="External Contract Number" />
        <InputText field="paymentTermsNetDays" name="Vendor Payment Terms" children={<em>Leave blank for Xero terms or net 60 day default.</em>} />
      </BoundCommit> 
    </Fragment>
  );
};

export { EditVendorContractDetails };

export function DisplayVendorContract(props) {
  const [files, setFiles] = useState([]);

  if (props.vendorContract == undefined) {
    return null;
  }

  const openAnnotations = (md5) => {
    APP.alert("hey " + md5);
  };

  return (
    <div className="edit-vendor-contracts">
      <Bound to={props.vendorContract} boundId="vendorContractNiceBlocks" disableEditing={true}>
        <NiceBoxSection>
          <NiceBoxContainer>
            <h4>Vendor Info</h4>
            <NiceBox label="Vendor #">
              <BoundView boundTo="vendor" key="vendor-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Vendor">
              <BoundView boundTo="vendor" key="vendor-name">
                <InputText field="name" disabled={true} />
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>

          <NiceBoxContainer>
            <h4>Contract Info</h4>
            <NiceBox label="Vendor Contract #">
              <BoundView key="contract-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Start">
              <BoundView key="contract-effectiveFrom">
                <DatePicker field="effectiveFrom" />
              </BoundView>
            </NiceBox>

            <NiceBox label="End">
              <BoundView key="contract-effectiveTo">
                <DatePicker field="effectiveTo" />
              </BoundView>
            </NiceBox>

            <NiceBox label="Early Termintation">
              <BoundView key="contract-earlyTermination">
                {(props.vendorContract && props.vendorContract.earlyTerminationClause) ? <span>{props.vendorContract.earlyTerminationClause}</span> : <span>Unset</span>}
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>
        </NiceBoxSection>

        <File showHistoricalSet={true} onFileList={(files) => setFiles(files)} />
      </Bound>
    </div>
  );
}

