import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import { Button, Icon, LookupInputSelect, Bound, Grid, InputText, BoundView, GridItem, Loading, Tabular, TabularColumn, ActionBar, InputCheckbox, SaveToolbar, FlexBreak, Tabbed, AccessControl, BoundMagic, InputSelect } from '@opidcore/components';
import * as _ from 'lodash';
import { BoundNiceRelativeTime,  NiceDate, NiceRelativeTime, NiceSiteSustainabilityStatus } from '../Nice';
import { Link, useParams } from 'react-router-dom';
import { File, NiceBox, NiceBoxContainer, StaffSelect } from '../../components';
import SupportRelatedItem from "../../components/SupportRelatedItem"
import { DataContext } from '@opidcore/components/Bound';
import IconNew from '../../components/IconNew';
import { CurrentModal } from '@opidcore/components/OpidApplication';
import ChangeLogs from '../ChangeLog/View';
import LookupStatusSelect from '@opidcore/components/LookupStatusSelect';

import Util from '@opidcore/Util'; 

import moment from 'moment';

import ExcelExportModal from '../../components/ExcelExportModal';
import FullTextSearchable from '../../components/FullTextSearchable';


function SupportPreview(props) {
	let [showSupportActions, setShowSupportActions] = useState(false);
	return <div key="support_preview" className="support_preview">
		<div>{props.task.subject != undefined ? <span className="subject">{props.task.subject}</span> : <span className="subject">No Task Subject</span>} {(props.taskTypeObject != undefined) ? <span className="taskType">{props.task.taskTypeObject.name}</span> : <span>No Task Type</span>}</div>
	</div>
}

const ZendeskTicketLink = ({ field, allowEditing = false }) => {
	const bound = useContext(DataContext);

	const [tickets, setTickets] = useState(bound.magicalGet(field));
	const [ticketsInfo, setTicketsInfo] = useState([]);
	const [participantInfo, setParticipantInfo] = useState([]);


	const [editing, setEditing] = useState(!(tickets && tickets.toString().length >= 5));

	useEffect(() => {
		bound.magicalState(field, setTickets);
    if (field && field.toString().length >= 5) {
      syncFromZendesk();
    }
	}, []);

	const syncFromZendesk = () => {
		if (tickets && tickets.toString().length >= 5) {
			APP.central.Zendesk.getTickets(tickets).then((r) => {

        //r = fakeData.default; // fake data for testing

				let tempTicketsInfo = [];
				_.forEach(_.filter(r.result.rows, (ticket) => !_.has(ticket, 'role')), (ticket) => {
					if (ticket == undefined) {
						tempTicketsInfo.push({ id: "No Match" });
					} else {
						tempTicketsInfo.push(ticket);
					}
				});

				updateTicketInfo(tempTicketsInfo);
				setParticipantInfo(_.filter(r.result.rows, (ticket) => _.has(ticket, 'role')));
				setTicketsInfo(tempTicketsInfo);

			});
		}
	};

	const updateTicketInfo = (info) => {
		if (!info) {
			info = ticketsInfo;
		}

		if (bound.magicalGet('subject') == '' && info[0] != undefined) {
			bound.magicalSet('subject', info[0].subject);
		}
		if (bound.magicalGet('body') == '' && info[0] != undefined) {
			bound.magicalSet('body', info[0].description);
		}
	}

	const doOpenTicketDisplayModal = () => {
		const modalId = _.join(_.map(ticketsInfo, (ticket) => ticket.id), ',');

		const ticketDisplays = _.map(ticketsInfo, (ticket) => {
			const title = "#" + ticket.id + "-" + ticket.status;
			return <ZendeskTicketsDisplay tickets={[ticket]} participants={participantInfo} title={title} />
		});

		if (ticketsInfo.length > 1) {
			ticketDisplays.unshift(<ZendeskTicketsDisplay tickets={ticketsInfo} participants={participantInfo} title={"All Tickets"} />);
		}

		APP.instance.createModal(<Tabbed inline={true} title="View Tickets" defaultTab={0}>{ticketDisplays}</Tabbed>, { modal_name: "Tickets - " + modalId });
	}

  const keyUp = (e)=>{
		if (e.key === 'Enter'){
      setEditing(false)
			syncFromZendesk();
		}
	}

	if (allowEditing && editing) {
		return (
			<Fragment>
				<InputText field={field} onKeyUp={e=>keyUp(e)} />
				<Icon icon="pencil" onClick={() => setEditing(false)} />
        <Icon icon="sync-alt" title="Refresh from Zendesk" onClick={() => syncFromZendesk()} />
			</Fragment>
		);
	} else {
		return (
			<Fragment>
				{/*_.map(ticketsInfo, (ticketInfo) => {
          return <Fragment><ZendeskLink ticket={ticketInfo}/><br/></Fragment>;
        })*/}
				{ticketsInfo && ticketsInfo.length > 0 ? (
					<Button onClick={() => doOpenTicketDisplayModal()} className="ticketButton">
						{ticketsInfo && ticketsInfo.length > 1 ? "View Tickets" : "View Ticket"}
					</Button>
				) : (
					<span>No Tickets</span>
				)}
				{/*<Button onClick={() => doOpenTicketDisplayModal()} className="ticketButton">
          {ticketsInfo && ticketsInfo.length > 1 ? "View Tickets" : "View Ticket"}
      </Button>*/}

				<Icon icon="pencil" title="Edit Tickets" onClick={() => setEditing(true)} />
				<Icon icon="sync-alt" title="Refresh from Zendesk" onClick={() => syncFromZendesk()} />
			</Fragment>
		);
	}
};

const ZendeskTicketsDisplay = (props) => {
	const tickets = props.tickets;
	const participants = props.participants;

	const [orderedComments, setOrderedComments] = useState([]);

	useEffect(() => {
		let tempComments = [];
		_.forEach(tickets, (ticket) => {
			_.forEach(ticket.comments, (comment) => {
				comment.ticket = { id: ticket.id, subject: ticket.subject, status: ticket.status };
				tempComments.push(comment);
			});
		});
		setOrderedComments(_.orderBy(tempComments, (comment) => moment(comment.created_at), ['asc']));
	}, [tickets]);

	return (
		<div>
			{
				_.map(orderedComments, (comment) => {
					const user = _.find(participants, { 'id': comment.author_id });

          let bgColor = '#E5E5E521'
          if(user) {
            bgColor = user.role == "end-user" ? '#CCDB2621' : '#27b2bc21';
          }

					return (
						<div className='ticketCommentDisplayRow' style={{ backgroundColor: bgColor }}>
							<div><Icon fType="fas" icon="circle" size="1x" color={bgColor.substring(0, 7)} />&nbsp;<b>{user ? user.name : "Name Unavailable"}</b></div>
							<br />
							<div dangerouslySetInnerHTML={{ __html: comment.html_body }} className="ticketCommentBody" />
							<br />
							<div style={{ display: "flex", fontWeight: "bold" }}>
								<NiceRelativeTime date={comment.created_at} />
								{","}&nbsp;
								<NiceDate showTime={true}>{comment.created_at}</NiceDate>
								<div style={{ marginLeft: "auto" }}>
									<ZendeskLink ticket={comment.ticket} />
								</div>
							</div>
						</div>
					);
				})
			}
		</div>
	);
};

export const ZendeskLink = ({ ticket }) => {
	if (ticket && ticket.id > 0) {
		return (
			<a target="_new" href={"https://wastecanada.zendesk.com/agent/tickets/" + ticket.id} title={ticket.subject}>
				#{ticket.id} - {ticket.status}
			</a>
		);
	}

	return null;
};

export function EditSingleTask(props) {
	let params = useParams();
  const [data, dataSet] = useData("task;"+params.id, { method: "fetchSingleTask", id: params.id });
  const navBar = useNavBar("Edit Case #" + (params.id ? params.id : data.id ? data.id : ""), []);

	if (dataSet.loading) {
		return <Loading />;
	}

	let actionItems = undefined;
	if (params.id > 0) {
		actionItems = <>
			<ChangeLogs model={data.length > 0 ? data[0] : {}} />
		</>;
	}

	return <div>
		<ActionBar model={data.length > 0 ? data[0] : {}} showNotes={false} showTasks={false}>
			<div style={{ flex: 5 }}></div>
			{actionItems}
		</ActionBar>
		{data.length > 0 ? <TaskDetails task={data.length > 0 ? data[0] : {}} dataSet={dataSet} /> : "Loading"}
	</div>

}

function TaskDetails(props) {
	const [task, setTask] = useState(props.task);
  //const bound = useMagic(props.task);
  const [bound, setBound] = useState(props.task)
	const [saving, setSaving] = useState(false);
	const [relatedId, setRelatedId] = useState(props.task.formInstanceId);
	const [formUID, setFormUID] = useState(props.task.formUID);
	const modal = useContext(CurrentModal);
  const [saveWarning, setSaveWarning] = useState(null);

	if (props.task == undefined) {
		return <div>Select a support case.</div>
	}

  const refreshDataSet = () => {
    if(props.dataSet) {
      APP.central.Util.clearCache(props.dataSet.uid).then(() => {
        props.dataSet.fetch();
      });
    }
  }

  const doOnChange = () => {
    if(!saveWarning) {
      fireSaveWarning(bound);
    } 
  }

  const fireSaveWarning = (bound) => {
    setSaveWarning(true);
  }

	const addActivity = () => {
    bound.magicalAppend("taskActivity", {
      __new: true,
      __type: "TaskActivity",
      //__dataUID: props.dataSet.dataUID,
      task: bound.magicalGet("id"),
      assignedToStaff: APP.getWebSession().userId,
      status: "new",
    });
	}

	const deleteActivity = (activity) => {
		const id = activity.to.id;
		if (id) {
			APP.confirm("Are you sure?", (res) => {
				if (res == true) {
					APP.central.TaskActivity.deleteActivity(activity.to).then((r) => {
						if (r.result == true) {
							bound.magicalSplice("taskActivity", bound.magicalGet("taskActivity").findIndex((a) => { return a.id == id }), true);
              APP.central.Util.clearCache(props.dataSet.uid);
						} else {
							APP.alert("An Error Occured, case task was not deleted, please alert the development team.");
						}
            if (bound && bound.getAllDeltas() && bound.getAllDeltas().length == 0) {
              setSaveWarning(false);
            }
					});
				}
			});
			return;
		}
		//no need to confirm if we never saved it, delete based on _keyPathPos
		bound.magicalSplice("taskActivity", bound.magicalGet("taskActivity").findIndex((a) => { return a._keyPathPos == activity.to._keyPathPos }), true);
	}

	const deleteTask = () => {
		APP.confirm("Are you sure you wish to delete this case?", (res) => {
			if (res == true) {
				APP.central.Task.deleteTask(task).then((r) => {
					if (!r.result) {
						APP.alert("An Error Occured, Case was not deleted, please alert the development team.");
						return;
					}
          refreshDataSet();
					if (modal && modal.id && modal.id != 0) {
						APP.instance.closeModal(modal.id);
						return;
					}
					APP.redirect("/ui/support/list")
				});
			}
		});
	}

  // unused due to magical interference
  const saveTaskActivities = () => {
    const activitiesToSave = bound.magicalGet("taskActivity");
    if (activitiesToSave.length == 0) {
      return Promise.resolve();
    }
    return APP.central.TaskActivity.saveTaskActivities(activitiesToSave);
  }
  
	const doSave = () => {
   		if(bound && !bound.requiredFieldsOk()){
			APP.alert("Failed to save. Please fill in all required fields.");
			throw new Error("Failed to save. Please fill in all required fields.");
		}
		/*
    if (bound && bound.getAllDeltas() && bound.getAllDeltas().length == 0) {
			APP.alert("No changes to save.");
			throw new Error("No changes to save.");
		}
    */

		setSaving(true);
    const activitiesToSave = bound.magicalGet("taskActivity", []);

    return APP.central.Task.saveChangesWithActivities(bound.to.id, bound.to, activitiesToSave).then((r) => {
      let theNewBound = _.cloneDeep(r.result);
      theNewBound.taskActivity = theNewBound.tempTaskActivities;
      delete theNewBound.tempTaskActivities;
      //setBound(theNewBound);
      setBound(BoundMagic.create(theNewBound, null, null, null, null));
      setSaving(false);
      setSaveWarning(false);
      setRelatedId(r.result.formInstanceId); 
      setFormUID(r.result.formUID); 

      refreshDataSet();
      
      if (props.modal == true && modal.id > 0) {
        APP.instance.closeModal(modal.id);
      } else {
        //location.reload();
      }
    });
    
    /* some weird magical interference stuff going on here, this is the old way of doing it, but it doesn't work
    return saveTaskActivities().then((ta) => {

      const theNewTaskActivities = _.cloneDeep(ta.result);
      console.log("theNewTaskActivities", theNewTaskActivities); //   <------------- how is this 
      APP.central.Task.saveChanges(bound.to.id, bound.to).then((r) => {
        console.log("theNewTaskActivities", theNewTaskActivities); // <-------------  different than this?

        let theNewBound = _.cloneDeep(r.result);
        theNewBound.taskActivity = theNewTaskActivities;
        bound.replaceTo(theNewBound);
        setSaving(false);
        setSaveWarning(false);
        setRelatedId(r.result.formInstanceId); // look into this @Cesar
        setFormUID(r.result.formUID); // look into this @Cesar
        if (props.modal == true && modal.id > 0) {
          APP.instance.closeModal(modal.id);
        } else {
          //props.dataSet.fetch();
        }
      })
    });
    */
     
	}

	if (task == null || task.id == null) {
		return <Loading />;
	}


	return (
    <div>
      <div className="full-size">
        <Bound to={bound} init={(thisBound) => setBound(thisBound)} boundId={"taskBound:" + task.id} onChange={() => doOnChange()}>
          <SupportOverview newTask={false} formInstanceId={relatedId} formUID={formUID} dataSet={props.dataSet} saving={saving} />

          <File showHistoricalSet={true} />

          <NotifyRelatedStaff task={task} bound={bound} doSave={doSave} />

          <h4>Tasks</h4>
          
          {saving ? null : (
            <Grid boundData="taskActivity" appendable={{ text: "Add Task", add: addActivity, column: 0 }}>
              <GridItem title="Status">
                <LookupStatusSelect lookupName="supportTaskStatus" field="status" />
              </GridItem>
              <GridItem title="Type">
                <LookupInputSelect what="lookup:taskActivityType" field="type" showEmpty={true} />
              </GridItem>
              <GridItem title="Person">
                <StaffSelect what="staff" field="assignedToStaff" />
              </GridItem>
              <GridItem title="Description">
                <InputText field="notes" inputType="textarea" />
              </GridItem>
              <GridItem title="Created" width={110}>
                <BoundNiceRelativeTime field="created" />
              </GridItem>
              <GridItem title="Last Updated" width={110}>
                <BoundNiceRelativeTime field="lastUpdated" />
              </GridItem>
              <GridItem>{saving ? <Fragment /> : <IconNew onClick={(bound, a, b, c) => deleteActivity(bound, a, b, c)} icon="trash" />}</GridItem>
            </Grid>
          )}
          
          {saving ? <Loading /> : (
            <SaveToolbar handleSave={() => doSave()} saveButtonStyle={saveWarning ? { backgroundColor: "#2cbd2c" } : null}>
              <Button onClick={() => deleteTask()}>Delete Case</Button>
            </SaveToolbar>
          )}
          {saveWarning ? "You have unsaved changes." : null}
        </Bound>
      </div>
      <div className="full-size">
        <div className="people">
          {task.taskPeople.map((taskPerson, i) => (
            <div className="person_container">
              <img src={taskPerson.person.related.avatar ? "/" + taskPerson.person.related.avatar : "/assets/images/client-avatar.png"} />
              <span className="name">{taskPerson.person.fullName}</span> <span className="role">{taskPerson.taskRole}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const NotifyRelatedStaff = ({task, bound, doSave}) => {
  let oldTaskActivityLength = useState(task.taskActivity.length);
  let taskRelatedNotes = "";

  if(task.related && task.related.__type) {
    taskRelatedNotes = "Related to " + task.related.__type; 
    if(task.related.name && task.related.name.length > 0){
    taskRelatedNotes =  taskRelatedNotes.concat("<br/>" + "Name: " + task.related.name);
    }
    if(task.related.friendlyId){
    taskRelatedNotes = taskRelatedNotes.concat("<br/>" + "Friendly ID: " + task.related.friendlyId );
    }
    if(task.related.id){
    taskRelatedNotes = taskRelatedNotes.concat("<br/>" +  "ID: " + task.related.id );
    }
  }

  const sendTaskNotificationEmail = (staffRole) => {
    const taskActivityLength = bound.magicalGet("taskActivity").length;

    const notifActivity = bound.magicalGet("taskActivity").length > 0 ? bound.magicalGet("taskActivity")[taskActivityLength-1] : null;
    if(notifActivity == null || notifActivity.type != "notification" || taskActivityLength == oldTaskActivityLength) {
      oldTaskActivityLength = taskActivityLength;
      return;
    }
    oldTaskActivityLength = taskActivityLength;
    
    APP.central.Staff.sendTaskNotificationEmail(task[staffRole+"Id"], notifActivity.notes, task.id, taskRelatedNotes).then((r) => {
      if (r.status == "ok") {
        APP.alert("Notification Email Sent.");
        doSave();
      } else {
        APP.alert("Failed to send notification email."); 
      }
    });
  }

  const notifyStaff = (staffRole) => { 
    oldTaskActivityLength = bound.magicalGet("taskActivity").length;
    APP.instance.createModal(
      <TaskNoteActivity bound={bound} staffToAssign={task[staffRole + "Id"]} statusToAssign="new" typeToAssign="notification" />,
      {
        modal_name: "New Notification " + task.id,
        className: "task-note-modal",
      },
      {
        afterClose: (modal) => sendTaskNotificationEmail(staffRole),
      }
    );
  };

  if(!task.regionalAccountManager && !task.billingAnalyst && !task.sustainabilityAnalyst) {
    return null;
  }

  return (
    <Fragment>
      <h4>Notify Related Staff</h4>
      <div className="notifyButtonContainer">
        {task.regionalAccountManager ? <Button onClick={() => notifyStaff("regionalAccountManager")} type="btn" className="btn-primary flexed">Account Manager</Button> : null}       
        {task.billingAnalyst ? <Button onClick={() => notifyStaff("billingAnalyst")} type="btn" className="btn-primary flexed">Billing Analyst</Button> : null}         
        {task.sustainabilityAnalyst ? <Button onClick={() => notifyStaff("sustainabilityAnalyst")} type="btn" className="btn-primary flexed">Sustainability Analyst</Button> : null}             
      </div>
    </Fragment>
  )

}

const TaskNoteActivity = ({ bound, staffToAssign = APP.getWebSession().userId, statusToAssign = "complete", typeToAssign = "note" }) => {
  const newActivityNote = { notes: "", status: statusToAssign, type: typeToAssign };
  const modal = useContext(CurrentModal);

  const doCreate = () => {
    bound.magicalAppend("taskActivity", {
      __new: true,
      __type: "TaskActivity",
      task: bound.magicalGet("id"),
      assignedToStaff: staffToAssign,
      status: newActivityNote.status,
      type: newActivityNote.type,
      notes: newActivityNote.notes,
    });

    APP.instance.closeModal(modal.id);
	}

  return (
    <div>
      <Bound to={newActivityNote}>
        <InputText field="notes" inputType="textarea" name="Description" style={{ height: "fit-content" }} />
        <LookupInputSelect what="lookup:taskActivityType" name="Type" field="type" showEmpty={true} />
        <LookupStatusSelect lookupName="supportTaskStatus" field="status" />
      </Bound>
      <div style={{ textAlign: "right", marginTop: "90px" }}>
        <Button onClick={doCreate}>Add Case Note</Button>
      </div>
    </div>
  );
};

export const SupportOverview = (props) => {
	const bound = useContext(DataContext)
	const task = bound.getBound();
	const [showVisibilitySelect, setShowVisibilitySelect] = useState(false);
	const [taskVisibilityData, taskVisibilityDataSet] = useData("taskVisibilityLookup;" + task.relatedTable, APP.central.Lookup.fetchForNameParams, { name: "taskVisibility", parameter1: task.relatedTable });
	const [editingSubject, setEditingSubject] = useState(false);

	useEffect(() => {
		if (taskVisibilityData.length > 0) {
			setShowVisibilitySelect(true);
		}
	}, [taskVisibilityDataSet.loading]);

	const doOpenTaskNoteActivity = () => {
		APP.instance.createModal(<TaskNoteActivity bound={bound} />, { modal_name: "New Case Note " + task.id, className: "task-note-modal" });
	}

	return (
		<div className="support-overview">

			{editingSubject ? (
				<div style={{ display: "flex" }}>
					<InputText field="subject" name="Subject" className="heading" />
					<Icon icon="pencil" onClick={() => setEditingSubject(false)} />
				</div>
			) : (
				<div style={{ display: "flex" }}>
					<h4>{bound.magicalGet("subject")}</h4>
					<Icon icon="pencil" onClick={() => setEditingSubject(true)} />
				</div>
			)}

			<NiceBoxContainer>
				<h4>Case #{task.id} Details</h4>
				<NiceBox label="Status">
					<BoundView boundTo={task} overrideEditing={bound.magicalGet("status") == "" ? true : false}>
						<LookupStatusSelect lookupName="supportTaskStatus" field="status" />
					</BoundView>
				</NiceBox>
				<NiceBox label="Zendesk Ticket">
					<BoundView boundTo={task} overrideEditing={bound.magicalGet("externalId") == "" ? true : false}>
						<ZendeskTicketLink field="externalId" allowEditing={true} />
					</BoundView>
				</NiceBox>
				<NiceBox label="Type">
					<BoundView boundTo={task} overrideEditing={bound.magicalGet("taskType") == "" ? true : false}>
						{/*<LookupInputSelect
							what="task_type"
							field="taskType"
							display={(tt) => {
								return tt.niceName ? tt.niceName : tt.name;
							}}
							showEmpty={true}
						/>*/}
            <TaskTypeSelect />
					</BoundView>
				</NiceBox>
				{showVisibilitySelect ? (
					<NiceBox label="Visibility">
						<BoundView boundTo={task} overrideEditing={bound.magicalGet("visibility") == "" ? true : false}>
							<LookupInputSelect what="lookup:noteType" parameter1={task.relatedTable} field="visibility" showEmpty={true} />
						</BoundView>
					</NiceBox>
				) : null}
				<NiceBox label={"Related Entity"}>
					<BoundView boundTo={task} disableEditing={true}>
						<TaskNiceSubject row={task} />
					</BoundView>
				</NiceBox>
				<NiceBox label="Created">
					<NiceRelativeTime date={task.created} />
				</NiceBox>
				<NiceBox label="Created By">
					<BoundView boundTo={task} disableEditing={true}>
						<InputText field="createdBy" />
					</BoundView>
				</NiceBox>
				<NiceBox label="Due">
					<NiceDate children={bound.to.due} />
				</NiceBox>
				<NiceBox label="Show on Portal?">
					<BoundView boundTo={task}>
						<InputCheckbox field="portalVisibility" />
					</BoundView>
				</NiceBox>
			</NiceBoxContainer>

			<InputText field="body" inputType="textarea" name="Description" className="taskBody" />

			<div className="action_button" style={{ width: "40px", marginTop: "5px", marginBottom: "15px" }} onClick={() => doOpenTaskNoteActivity()} title={"Update Note"}>
				<Icon icon="plus" size="2x" />
			</div>

			<SupportRelatedItem formUID={props.formUID} saving={props.saving} />
		</div>
	);
}

const TaskTypeSelect = (props) => {

  const [aclData, aclDataSet] = useData("acl_group");

  const [options, setOptions] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskACLGroups, setTaskACLGroups] = useState([]);

  useEffect(() => {
    APP.central.TaskType.list().then((taskTypeList) => {
      setTaskTypes(taskTypeList.result);
      APP.central.TaskACLGroup.list().then((taskACLGroupList) => {
        setTaskACLGroups(taskACLGroupList.result);
      });
    });
  }, []);

  useEffect(() => {
    if(taskTypes.length == 0 || taskACLGroups.length == 0 || aclData.length == 0) {
      return;
    }

    // will be an array of acl_group ids that the user is a member of
    let userACLGroups = []; 
    _.forEach(APP.getWebSession().acl_groups, (aclGroupName) => {
      const aclGroup = _.find(aclData, (acl) => {
        return acl.name == aclGroupName;
      });
      if(aclGroup) {
        userACLGroups.push(aclGroup.id);
      }
    });

    // will be an array of option objects that will be used to populate the select
    // in the format {id: #, niceName: "niceName"}
    const tempOptions = [];
    let addOption = false;
    _.forEach(taskTypes, (taskType) => {
      let hasACL = false;
      _.forEach(taskACLGroups, (taskACLGroup) => {
        if(taskACLGroup.taskType && taskACLGroup.taskType.id == taskType.id) {
          hasACL = true;
          if(_.includes(userACLGroups, taskACLGroup.aclGroup)) {
            addOption = true;
          }
        }
      });
      
      if(!hasACL || addOption) {
        tempOptions.push({key: taskType.id, value: taskType.niceName});
      }
    });

    setOptions(tempOptions);
  }, [taskTypes, taskACLGroups, aclDataSet.loading]);

  if(options.length == 0) {
    return <div>Loading...</div>;
  }

  return <InputSelect field="taskType" options={options} />;
}

export default function ListSupport(props) {
	const navBar = useNavBar("Support Cases", [], { menu: ["support"] })
	//const [data, dataSet] = useData("task;all", { method: "fetchAllTasks", paginate: 50, orderBy: "id desc" });
  const [tasks, setTasks] = useState([]);
  const [fetchTasks, setFetchTasks] = useState(false);


	const handleClick = (row, e) => {
		if (e.defaultPrevented == false) {
			APP.redirect("/ui/support/case/" + row.id);
		}
	};

  const handleOnAuxClick = (row, e) => {
    if (e.defaultPrevented == false && e.button == 1) {
      e.preventDefault();
      e.stopPropagation();
      window.open("/ui/support/case/" + row.id, '_blank');
    }
  }

  // Returns user's fullname
  const getDefaultCreator = () => {
    const fullName = APP.getWebSession().fullname;
    return fullName;
  }

  const refreshDataSet = () => {
    setFetchTasks(true);
  }

  const doOpenInModal = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    APP.instance.createModal("/ui/support/case/" + row.id, {afterClose: () => refreshDataSet(), modal_name: "Case #" + row.id})
  }

  //TODO FIX THIS
  const excelExport = () => {
    APP.central.Task.getDefaultExportLayout().then((r) => {
      APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"Task"}/>, { modal_name: "Excel Export" });
    });
  };

	return (
    <div>
      <ActionBar title="Actions">
        {/*<AccessControl resource="Customer Care Admin">
          <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
        </AccessControl>*/}
        <FullTextSearchable 
          model={"Task"} 
          method={"reasonableList"} 
          setData={setTasks} 
          doFetchData={fetchTasks} 
          setDoFetchData={setFetchTasks} 
          maxRows={500}
          allowBlank={true}
          filterOptions={{
            columns: [
              { column: "created_by", heading: "Created By", default: getDefaultCreator() },
              { column: "assigned_to_staff_name", heading: "Assigned To" },
              { column: "regional_account_manager", heading: "Account Manager" },
              { column: "billing_analyst", heading: "Billing Analyst" },
              { column: "sustainability_analyst", heading: "Sustainability Analyst" },
              { column: "customer_name", heading: "Client" },
              { column: "task_category", lookup: "taskCategory", heading: "Case Category" },
              { column: "related_table", heading: "Related" },
              { column: "status", heading: "Status", defaults: ["new", "in_progress"] },
            ]
          }} 
        />
      </ActionBar>

      <Tabular data={tasks} onClick={handleClick} onAuxClick={handleOnAuxClick} noData="Loading or found 0 cases. Clear filter to see all." pageSize={50} paginationLocation={"both"}>
        {CaseListTabularColumns}
        <TabularColumn title="Open in Modal" data={(row) => <Icon icon="pencil" onClick={(e) => doOpenInModal(e, row)} />} />
      </Tabular>
    </div>
  );
	
}

export const CaseListTabularColumns = [
  <TabularColumn title="Case #" data={(row) => <span>{row.id}</span>} />,
  <TabularColumn title="Zendesk Ticket" data={(row) => <span>{row.external_id ? row.external_id : "-"}</span> }/>,
  <TabularColumn title="Title" data={(row) => <span>{row.subject != null ? row.subject : "No subject."}</span>} />,
  <TabularColumn title="Type" data={(row) => <span>{row.task_type_name ? row.task_type_name : "No type."}</span>} />,
  <TabularColumn title="Status" data={(row) => <Bound to={row}><LookupStatusSelect field="status" lookupName="supportTaskStatus" disabled={true} /></Bound>} />,
  <TabularColumn title="Subject" data={(row) =>  <TaskLessThanNiceSubject row={row}/> } />,
  <TabularColumn title="Created" data={(row) => <NiceRelativeTime date={row.created} />} />,
  <TabularColumn title="Created By" data={(row) => <span>{row.created_by}</span>} />,
  <TabularColumn title="Assigned To" data={(row) => <span>{row.assigned_to_staff_name}</span>} />,
];

const TaskNiceSubject = ({ row }) => {
	if (row.related == null || row.related.likelyDescription == null) {
		return <span>??</span>;
	}
	switch (row.relatedTable) {
		case "Customer":
			return <Link to={"/ui/clients/" + row.related.friendlyId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "Site":
			return <Link to={"/ui/sites/detail/" + row.related.friendlyId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "Vendor":
			return <Link to={"/ui/vendors/edit/" + row.related.friendlyId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "ClientContract":
			return <Link to={"/ui/clientcontract/edit/" + row.related.friendlyId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "VendorContract":
			return <Link to={"/ui/vendorcontract/edit/" + row.related.friendlyId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "APInvoice":
			return <Link to={"/ui/apinvoice/" + row.relatedId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "ARInvoice":
			return <Link to={"/ui/arinvoice/" + row.relatedId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "Service":
			return <Link to={"/ui/servicewrong/" + row.relatedId} className="plain_link"><span><b>{Util.deCamelCase(row.relatedTable) + ": "}</b> {row.related.likelyDescription}</span></Link>
		case "SiteSustainabilityStatus":
			return <NiceSiteSustainabilityStatus link={true}>{row.relatedId}</NiceSiteSustainabilityStatus>
		default:
			return <span>{"No link set up for " + Util.deCamelCase(row.relatedTable) + ", please let the development team know."}</span>
	}
}

const TaskLessThanNiceSubject = ({ row }) => {

	switch (row.related_table) {
		case "Customer":
			return <Link to={"/ui/clients/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.customer_name}</span></Link>
		case "Site":
			return <Link to={"/ui/sites/detail/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.site_name}</span></Link>
		case "Vendor":
			return <Link to={"/ui/vendors/edit/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.related_id}</span></Link>
		case "ClientContract":
			return <Link to={"/ui/clientcontract/edit/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.related_id}</span></Link>
		case "VendorContract":
			return <Link to={"/ui/vendorcontract/edit/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.related_id}</span></Link>
		case "APInvoice":
			return <Link to={"/ui/apinvoice/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.related_id}</span></Link>
		case "ARInvoice":
			return <Link to={"/ui/arinvoice/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.related_id}</span></Link>
		case "Service":
			return <Link to={"/ui/servicewrong/" + row.related_id} className="plain_link"><span><b>{Util.deCamelCase(row.related_table) + ": "}</b> {row.related_id}</span></Link>
		case "SiteSustainabilityStatus":
			return <NiceSiteSustainabilityStatus link={true}>{row.related_id}</NiceSiteSustainabilityStatus>
		default:
			return <span>{"No link set up for " + Util.deCamelCase(row.related_table) + ", please let the development team know."}</span>
	}
}


