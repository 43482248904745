//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';

import { DataContext } from './Bound';

import * as _ from 'lodash';

const trueish = (v)=>{
	if (v == "true" || v == true || v == 1)
		return true
	
	if(v == "false" || v == false || v == 0)
		return false;

   return false;		   
}

export default class InputSelect extends BoundComponent {

  static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };
  
  static contextType = DataContext;

  constructor(props) {
	  super(props);
	  
	  var state = {
				  value: undefined,
				  showSelect: false,
				  options: {},
				  allOptions: []
		  };
	  
	  this.handleChange = this.handleChange.bind(this);
	 
	  this.state = state;
  }
  

	componentDidMount(){
		let boundValue = this.context.magicalGet ? this.context.magicalGet(this.props.field, null) : null;
		const boundValueIsBoolean = typeof boundValue == "boolean";
		if(boundValueIsBoolean){
			boundValue = trueish(boundValue);
		}
		
		if(boundValue != undefined){
	    	this.setState({value: boundValue});
			if(this.props.update){
				this.props.update(this.props.obj, this.props.field, boundValue); 
			} 
	    }
  	}

	componentDidUpdate(prevProps, prevState){
		let propsname = this.props.name;
		let propsField = this.props.field;
		let boundValue = this.context.magicalGet ? this.context.magicalGet(this.props.field, null) : null;
		const boundValueIsBoolean = typeof boundValue == "boolean";
		if(boundValueIsBoolean){
			boundValue = trueish(boundValue);
		}
		if(boundValue == null){
			if(this.state != undefined &&  (this.state.value == undefined || (this.state.value.trim != undefined && this.state.value.trim().length == 0))){
				//we don't have a value set
				if(this.props.forcedDefault == true && this.state.allOptions.length > 0){
					//we want to force a default value and have our options list populated
					let forcedValue = null;
					if(this.props.defaultValue != undefined){
						forcedValue = _.find(this.state.allOptions, (option)=>{ return option.props.value == this.props.defaultValue });
					}

					if(forcedValue == null){
						forcedValue = _.filter(this.state.allOptions, (option)=>{ return option.props.value != undefined && option.props.value.trim().length > 0 })[0];
					}

					if(forcedValue != undefined && React.isValidElement(forcedValue)){
						//found a valid option in our options list
						this.forceValue(forcedValue.props.value);
					}
				}
			}
		} else {
			//we actually have a bound value now so see if it needs to replace state value
			let shouldSet = false;
			if(boundValueIsBoolean){
				if(boundValue != trueish(this.state.value)){
					shouldSet = true;
				}
			} else if(boundValue != this.state.value){
				shouldSet = true;
			}
			if(shouldSet){
				this.forceValue(boundValue);
			}
		}
	}

	static createAllOptions(props, state){
		let propsname = props.name;
		let propsField = props.field;
		let currentOptions = [];
		
		if (Array.isArray(props.options)){
			//ignore all previous stuff and use the array
			currentOptions = _.map(_.compact(_.uniq(props.options)), function(option){  
				if (typeof option == "object"){
					 const optionHash = {value: option.key, label: option.value};
					 if(option.customClass){
						optionHash.customClass = option.customClass;
					 }
					 return optionHash;
				}else{
					// this is for a regular array of values
					return {value: option, label: option};
				}
			});
		} else {
			currentOptions = _.map(state.options, function(v,k){  
				return {value: k, label: v} 
			});
		}	

		if(props.sortBy){
			currentOptions = _.sortBy(currentOptions, props.sortBy);
		} else {
			currentOptions = _.sortBy(currentOptions, (o)=>{
				if(o.label == undefined){
					return "";
				}

				if(o.label.toLowerCase){
					return o.label.toLowerCase();
				}  

				return o.label;
			});
		}

		let allOptions = currentOptions.map((opt)=>{
			return <option key={opt.value} value={opt.value} className={opt.customClass? opt.customClass : undefined}>{opt.label}</option>
		});

		return allOptions;
	}

	static evaluateLabel = (props, option) =>{
		let label = "";
		if(props.display != undefined){
			if (typeof props.display == "function") {
				label = props.display(option);
			} else {
				label = option[props.display];
			}
		} else {
			label = props.labelKey ? option[props.labelKey] : option.name;
		}
		
		return label;
	}

  static getDerivedStateFromProps(props, state){
	  // componentWillReceiveProps(nextProps);
	  var state;
	  if (Array.isArray(props.options)){
		  state = {};
		  var options = {};
		  _.compact(props.options).forEach((opt) => {
		  	if(opt.key != undefined){
		  		options[opt.key] = opt.value;
		  	} else {
		  		options[opt] = opt;
		  	}
	  	});
		  state.options = options;
	  }else if (props.optionsCollection != null){
		  state = {};

		  state.options = _.fromPairs(props.optionsCollection.map((a) => [(a.id? a.id : a[props.fieldKey]), InputSelect.evaluateLabel(props, a)] ));

	  }else{
		  state.options = {...props.options}
	  }
	  
		if(_.keys(state.options).length > 0){
			state.allOptions = InputSelect.createAllOptions(props, state);
		}

		if(_.keys(state.options).length == 0){
			state.allOptions = [];
		}

	  return state;
  }

  buzzah(a,b){
	  this.context.magicalSet(a, b, {silent: true});
  }

  forceValue(value){
	let shouldRunSetter = true;

	if (value == "..." || (this.props.appendableLabel && value == this.props.appendableLabel)){
		if(this.props.handleAppendClicked){
			this.props.handleAppendClicked();
			shouldRunSetter = false;
		}else{
			value = prompt("Add:");
			this.setState({'addedOption': value});    			
		}
	} else {
		this.setState({ value: value }, ()=>{
			if(this.props.transferFocus != undefined){
				this.props.transferFocus();
			}
		});
	}

	if(this.props.update){
		shouldRunSetter = this.props.update(this.props.obj, this.props.field, value, this.context);
	}

	if (this.props.emptyIsNull == true && value == ""){
		shouldRunSetter = false;
		this.context.magicalSet(this.props.field, null);
	}
	
	if(shouldRunSetter != false){
		if(this.context != undefined){
			const existing = this.context.magicalGet(this.props.field);
			if ((existing == undefined && value != undefined) || (existing != value && existing.id != value)){
				if (this.props.store == "object") {
					this.context.magicalSet(this.props.field, _.find(this.props.optionsCollection, (r) => r.id == value))
				} else {
					this.context.magicalSet(this.props.field, value);
				}
			}
		} else {
			console.log("InputSelector.js: No context found for ", this);
		}
	}	
  }
  
  handleChange(e) {
	const value = e.target.value;
	let propsname = this.props.name;
	let propsField = this.props.field;
	
    if(this.props.field != undefined && this.props.field != "confirmPassCrypt"){
    	this.forceValue(value);
    } else {
    	this.setState({ value: value }, ()=>{
			if(this.props.transferFocus != undefined){
		    	this.props.transferFocus();
		    }
		});
    }
	if(this.props.onChange){
		this.props.onChange(this.context.to, this.props.field, value, this.context);
	}

    
  }
  
  handleClickText(showSelect){
	  if(this.state.showSelect != showSelect){
		this.setState({showSelect: showSelect}, ()=>{
			//this.openIfNeeded();
		});
	  }
	  
  }
  
  handleFocus(e){
	  if (this.state.showSelect != true){
		  this.focusNeeded = true;
		  this.setState({showSelect: true},()=>{
			  this.focusIfNeeded();
		  });
	  }
  }

  focusIfNeeded(){
	  
	  if (this.focusNeeded){
		  setTimeout(()=>{
			  if (this.refs.select != null){
				  this.refs.select.focus();
			  }
			  
			 this.focusIfNeeded(); 
		  },20);
	  }
  }
  
  gotAValue(value){
	let propsname = this.props.name;
	let propsField = this.props.field;
    if((value != null && value != undefined) && this.state.value != value){
      this.setState({value: value});
    }
	else if (value == null || value == undefined) {
		setTimeout(() => this.setState({value: value}), 100)
	}
  }

  handleKeyUp(e){
	//debugger;
  }

  handleKeyDown(e){
	  if (e.key == 'ArrowLeft' || e.key == 'ArrowRight' || e.key == 'ArrowUp' || e.key == 'ArrowDown'){
		  e.preventDefault();
		  //debugger;
	  }	
  }

  render() {	  
    var name = this.props.name;
	let currentVal = this.state.value; 
	let field = this.props.field;
	let fieldEmpty = false; 

	if(field === "compensationType"){ 
		if(Object.keys(this.state.options).length === 0){ 
		fieldEmpty = true;
		}
	}
	else if(!currentVal || (currentVal && currentVal instanceof Object && Object.keys(currentVal).length === 0) || (currentVal && currentVal.length && currentVal.length === 0)){
		fieldEmpty = true;
	}
	
	let propsHandleChange = this.props.handleChange; 
	if(this.listeningForChanges != this.context && this.context.magicalState){
		this.listeningForChanges = this.context;
		this.context.magicalState(this.props.field, (value)=>this.gotAValue(value));
	}

    var inputType = "text";
    if( this.props.field === "passCrypt" || this.props.field === "confirmPassCrypt" ){
    	inputType = "password";
    }
    
    let allOptions = [...this.state.allOptions];
   
    if  (this.props.showEmpty == true || this.props.showEmpty == undefined || (typeof this.props.showEmpty == "string" && this.props.showEmpty.indexOf("true") > -1)){
    	allOptions.unshift(<option key={null}></option>);
    }
    
    if  (this.state.addedOption){
    	allOptions.push(<option key={this.state.addedOption}>{this.state.addedOption}</option>);
    }else if (this.props.appendable){
    	allOptions.push(<option key={"..."}>{this.props.appendableLabel || "..."}</option>);
    }
    if  (this.props.hint){
    	allOptions.unshift(<option key={null} selected value="">{this.props.hint}</option>);
    }
    
    
    let value = this.state.value;
    if ((! _.isNull(value)) && typeof value == "object"){
    	value = value.id;
    }
    
    let disabled = this.props.disabled ? true : false;
    
    if(value == undefined || value === ""){
		value = this.props.defaultValue;
    }

	if(this.props.readOnly != undefined && this.props.readOnly == true){
		let displayValue = "";

		try{
			displayValue = this.props.options[value];
		}catch(e){ /** this could be null? */ }
		
		if (typeof _.first(this.props.options) == "object"){
			const selected = _.find( this.props.options, (o)=>o.key == value );
			if (selected){
				displayValue = selected.value;
			}else{
				displayValue = '?';
			}
		}

		if (typeof _.first(this.props.optionsCollection) == "object"){
			let selected = _.find( this.props.optionsCollection, (o)=>o.id == value );
			if (selected == null && typeof(value) == "object" ){
				selected = value;
			}
			if (selected){
				displayValue = selected[ this.props.display ? this.props.display : this.props.labelKey? this.props.labelKey : "name" ];
				return <div className="readOnly-field">{displayValue}</div>
			}else{
				displayValue = '?';
			}
		}
		
		return <div className="readOnly-field">{displayValue}</div>;
	}
    
    const showSelect = this.props.selectAsText == true ? (this.state.showSelect || value == null) : true;
    
	if (this.context.isReadOnly()){
		disabled = true;
	}

    const theField = showSelect ? <select
    		ref="select"
            type={inputType}
  	value={value == undefined? "" : value}
    className={"form-control"}
    placeholder={this.props.placeholder || ""}
    onChange={this.props.handleChange || this.handleChange} 
    onMouseLeave={()=>this.handleClickText(false)} 
	onKeyUp={(e)=>this.handleKeyUp(e)}
	onKeyDown={(e)=>this.handleKeyDown(e)}
    onBlur={()=>this.handleClickText(false)}
	title={this.props.title}
  	disabled={disabled}>
  	{allOptions}
  </select> : <span tabIndex="0" className="select-text" onFocus={(e)=>this.handleFocus(e)} onMouseLeave={()=>this.handleClickText(false)} onMouseEnter={()=>this.handleClickText(true)} >{this.state.options[value]}</span>;
    
  
	  let label = "";
	  if(this.props.hideLabel == true){
	  	//do nothing
	  } else if(name != undefined){
	  	label = <label>{name} {(this.props.labelExtra || this.props.children) ? this.props.children : ''} {this.renderLabelTags()}</label>;
	  }

	  let className = this.props.className;

	  if (this.state.hasError){
		className += " error";
	  }
  
    return (
		<div className={"field " +  (className) } style={this.props.style}>  
			{this.props.labelChildren}   
			{label}
			{(this.props.invReqField && fieldEmpty) ?
			   <div style={{border: "1px solid red", borderRadius: "0.3rem"}}>{theField}</div> 
			:
			   <div>{theField}</div> 
			}			
		</div> 

    );
  }
}

