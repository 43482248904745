import React, { Component, useState, useEffect, useContext, Children } from 'react';
import BoundComponent from './BoundComponent';
import Util from '../Util.js';
import * as _ from 'lodash';
import { Icon } from '.';
import PropTypes from 'prop-types'

export const DataContext = React.createContext(
	{
		"error_no_data": "Read the documentation on how to use opidcore's bound!",
		children: {},
		needsADad: 1,
		helloDad: function (a) {
			console.log("Bound.js:  i have bound commits inside this "); 
		},
		magicalGet: null,
		magicalSet: undefined,
		magicalAppend: undefined,
		magicalSplice: undefined,
		getParentMagic: undefined,
		getBound: undefined,
		to: undefined,
		magicalState: undefined
	}
);

export class BoundMagic {
	constructor(to, me, callback) {
		this.to = to;
		this.me = me;
		this.callback = callback;
		this.deltas = [];
		this.stateWatchers = {};
		this.parentMagic = undefined;
		this.boundId = undefined;
		this.onDeltaChange = undefined;
		this.readOnly = false;
		this.permissions = {};
		this.requiredFields = {};
		
		if (me && me.props && me.props.permissions){
			this.permissions = me.props.permissions;
			if (this.permissions.w == false){
				this.readOnly = true;
			}
		}

		
		if (this.to != null && this.to._ourMagic != null){
			const toCopy = {};
			toCopy.parentMagic = this.to._ourMagic.parentMagic;
			toCopy.stateWatchers = this.to._ourMagic.stateWatchers;
			this.to._ourMagic =  this;
			this.to._ourMagic.parentMagic = toCopy.parentMagic;
			this.to._ourMagic.stateWatchers = toCopy.stateWatchers;
		}
	}
	isReadOnly(){
		return this.readOnly;
	}


	register(){
		
		APP.registerBoundMagic(this.boundId,this);
	}

	getParentMagic() {
		if (this.parentMagic != undefined) {
			return this.parentMagic;
		}

		if(this.to != undefined && this.to._ourMagic != undefined && this.to._ourMagic.parentMagic != undefined){
			return this.to._ourMagic.parentMagic;
		}

		return undefined;
	}

	static isMagic(it) {
		if (it && it.constructor && it.constructor.name == "BoundMagic"){
			return true;
		}

		if (_.intersection(_.keys(it), ['to','me','boundId']).length == 3){
			return true;
		}
		
		return false;
	}

	static create(to, me, callback, parentMagic, options) {
		let theBoundMagic = null;
		if (to._ourMagic) {
			theBoundMagic = to._ourMagic;
		} else {
			theBoundMagic = new BoundMagic(to, me, callback);
		}
		if (parentMagic) {
			theBoundMagic.parentMagic = parentMagic;
			theBoundMagic.parentMagic.kid = theBoundMagic;
		}

		if (options){
			if (options.boundId){
				APP.registerBoundMagic(options.boundId, theBoundMagic);
			}
		}


		return theBoundMagic;
	}

	getCommit() {
		if (this.me.props.commit) {
			return this.me.props.commit;
		}

		return null;
	}

	attachInnerFields(them) {
		this.innerFields = them;
	}

	getIdOr(defaultId) {
		if (this.to.uid) {
			return this.to.uid;
		}

		if (this.to.id != undefined) {
			return this.to.id;
		}

		return defaultId;
	}

	isDirty() {
		if (this.to == null) {		
			return false;
		}
		let myDelta = this.to.__delta;
		const moreDeltas = this.deltas || [];
		if (_.keys(myDelta).length > 0 || moreDeltas.length > 0){
			return true;
		}

		let myChildrensDeltaChanges = _.filter(_.flattenDeep([this.to, _.values(this.to)]), (t) => t && t.__delta && _.keys(t.__delta).length > 0);
		if (myChildrensDeltaChanges.length > 0) {
			return true;
		}

		return false;
	}

	resetDelta() {
		if (this.to != undefined) {
			this.to.__delta = {};
		}
	}

	getDelta() {		
		//if it's new we should clone our whole object
		const delta = this.to.__new ? _.clone(this.to) : _.clone(this.to.__delta) || {};
		if (_.keys(delta).length <= 0) {
			return null;
		}

		delta.__type = this.to.__type;
		delta.__dataUID = this.to.__dataUID;
		delete delta._ourMagic;
		delete delta.__self;
		return delta;
	}

	getAllDeltas() {
		const ret = [];
		if (this.to) {
			const myDelta = this.getDelta();
			if (myDelta != null && myDelta.__deleted != true) {
				ret.push({ id: this.to.id, delta: myDelta });
			}
		}

		_.filter(this.deltas, (d) => {
			if (!_.isEqual(d, ret[0]) && d.delta.__deleted != true) {
				ret.push(d);
			}
		});

		return ret;
	}

	addAlsoCommit(thingToDo) {
		if (this.alsoCommit == null) {
			this.alsoCommit = [];
		}

		this.alsoCommit.push(thingToDo);
	}

	requiredFieldsOk(){
		let ok = true;
		if(this.requiredFields != undefined){
			_.forEach(this.requiredFields, (val, key)=>{
				if(val == "" || val == null){
					ok = false;
				}
			});
		}

		return ok;
	}

	setRequiredField(field, value){
		if(this.requiredFields == undefined){
			this.requiredFields = {};
		}

		if(field != null && field != ""){
			this.requiredFields[field] = value == ""? null : value;
		}

		let parentMagic = undefined;
		if(this.parentMagic){
			parentMagic = this.parentMagic;
		} else if(this.me && this.me.context && this.me.context.parentMagic){
			parentMagic = this.me.context.parentMagic;
		}

		if (parentMagic) {
			parentMagic.setRequiredField(this.boundId + ";" + field, value);
		}
	}

	magicalSet(keyPath, value, opts) {
		if (this.isReadOnly()){
			return;
		}

		let before = null;
		let after = null;
		let isSilent = opts && opts.silent ? true : false;
		let forceDiff = null;

		if(this.requiredFields != undefined && keyPath in this.requiredFields){
			this.setRequiredField(keyPath, value);
		}

		if (this.to && this.to.__proto__ && this.to.__proto__.isReactComponent) {
			//assume we can setState
			before = { ...this.to.state };
			var stateChange = {};
			stateChange[keyPath] = value;
			this.to.setState(stateChange, () => {

			});
			after = { ...this.to.state, ...stateChange };
		} else if (this.to) {
			if (isSilent == false) {
				this.to.__modified = Date.now();
			}
			before = JSON.parse(Util.stringify(this.to)); 

			// console.log("we modified this now: ", keyPath, value, opts);
			if (keyPath && keyPath.split('.').length == 1) {
				this.to[keyPath] = value;
			} else if (keyPath && keyPath.split('.').length == 2) {
				var chunks = keyPath.split('.');

				if (Array.isArray(this.to[chunks[0]])) {
					//
				} else if (typeof this.to[chunks[0]] == "object") {
					//
				} else {
					this.to[chunks[0]] = [];
				}


				if (Array.isArray(this.to[chunks[0]])) {
					if (chunks[1].indexOf == undefined || chunks[1].indexOf("_reference") == -1) {
						this.to[chunks[0]][chunks[1]] = value;
					}
				} else {
					//its a hash I hope!
					this.to[chunks[0]] = { ...this.to[chunks[0]] };
					this.to[chunks[0]][chunks[1]] = value;
					if (this.to[chunks[0]]['_saveFull']){
						forceDiff = {};
						forceDiff[chunks[0]] = this.to[chunks[0]];
					}
				}

				if (value == null) {
					if (this.to[chunks[0]].splice) {
						this.to[chunks[0]].splice(parseInt(chunks[1]), 1);
					}
				}
			} else if (keyPath && keyPath.split('.').length == 3) {
				var chunks = keyPath.split('.');
				if (!Array.isArray(this.to[chunks[0]])) {
					this.to[chunks[0]] = [];
				}
				if (this.to[chunks[0]][chunks[1]] == null) {
					this.to[chunks[0]][chunks[1]] = {};
				}
				this.to[chunks[0]][chunks[1]][chunks[2]] = value;

				if (value == null) {
					//this.to[chunks[0]].splice(parseInt(chunks[1]), 1);
				}
			} else {
				//console.log("your chunks are f'd up");
			}

			after = JSON.parse(Util.stringify(this.to)); 

			var shouldUpdate = true;
			if (opts && opts.silent) {
				shouldUpdate = false;
			}
			if (shouldUpdate && this.me) {
				//this is required for now? @ian -> useBoundEffect needs it
				this.me.forceUpdate();
			}
		}

		var shouldDoOnChange = true;
		if (opts && opts.silent) {
			shouldDoOnChange = false;
		}

		if (this.me) {
			if (this.me.props.onChange && shouldDoOnChange) {
				this.me.props.onChange(this.me, this, keyPath, value);
			}

			if (this.me.context.magicalGet != null) {
				if (this.me.context.me && this.me.context.me.props.onChange) {
					this.me.context.me.props.onChange(this.me, this, keyPath, value);
				}
			}

			if (this.me.context._parentOnChange) {
				this.me.context._parentOnChange(this.me, this, keyPath, value);
			}
		}
		
		let diff = Util.objectDifference(after, before);
		if (forceDiff != null){
			Util.OpidMerge(diff, forceDiff);
		}

		if (_.keys(diff).length > 0) {
			if (this.to.__delta == undefined) {
				this.to.__delta = {};
			}
			//_.merge(this.to.__delta, diff); //if diff has an undefined value it won't be applied
			//this.to.__delta = {...this.to.__delta, ...diff} //doesn't deep merge
			Util.OpidMerge(this.to.__delta, diff); //uses _.mergeWith
			this.pushDelta({ id: this.to.id, delta: this.getDelta() }); 
		}

		let callbacksAndWatchers = true;

		// Don't update callbacks and watchers when silent... 
		if (opts && opts.silent) { //|| (keyPath == "quantity" && value == "") || (keyPath == "unitPrice" && value == ""))
			callbacksAndWatchers = false;
		} 

		if (callbacksAndWatchers){
			this.callbacks(diff, opts);
			this.listenTos(diff, opts);

			this.updateWatchers(keyPath); 
		}
	}

	callbacks(diff, opts) {

		if (this.callback) {
			this.callback(this.to, diff, opts);
		}
	}

	updateWatchers(keyPath) {
		if (this.stateWatchers[keyPath] || this.stateWatchers["*"]) {
			const watchers = this.stateWatchers[keyPath] || [];
			if (this.stateWatchers["*"]){
				if(keyPath != "*"){
					watchers.push( this.stateWatchers["*"] );
				}
			}

			_.each( watchers, (watcher)=>{
				if (watcher.setter){
					watcher.setter(this.magicalGet(keyPath));
				}
	
				if (watcher.watcher){
					watcher.watcher(this.magicalGet(keyPath),keyPath,this,"update");
				}

			} );
		}

		if (this.magicalGet(keyPath,null) != null && this.magicalGet(keyPath)._ourMagic ){
			this.magicalGet(keyPath)._ourMagic.updateWatchers("*");
		}
	}

	updateAllWatches() {
		_.map(this.stateWatchers, (watchers, keyPath) => {			
			_.each( watchers, (watcher)=>{				
				if (watcher.setter){
					watcher.setter(this.magicalGet(keyPath));
				}
				if (watcher.watcher){
					watcher.watcher(this.magicalGet(keyPath),keyPath,this,"updateAll");
				}
			});
		});
	}

	listenTos(diff, opts) {
	
		if (this.parentMagic) {
			if (this.parentMagic.me && this.parentMagic.me.props.listenTo) {
				//check if there are multiple things to listen to
				//same as below 
				_.forEach(this.parentMagic.me.props.listenTo, (listenFunction, listenKey) => {
					if (listenKey == diff.__type) {
						console.log("Bound.js: running an extra listenTo callback for " + listenKey + " on: ", this.parentMagic);
						listenFunction(diff, this.to, opts);
					}
				});

			}

			if (this.parentMagic.listenTos) {
				this.parentMagic.listenTos(this.to);
			} else {
				console.log("Bound.js we don't really have any parents");
			}
		}

		let theMeIWant = null;

		if (this.me && this.me.context && this.me.context.me && this.me.context.me.props.listenTo) {
			theMeIWant = this.me.context.me;
		}else if (this.me && this.me.props.listenTo) {
			theMeIWant = this.me;
		}
		
		if (theMeIWant != null){
			//same as above except for diff/to.__type ^^^^^^
			_.forEach(theMeIWant.props.listenTo, (listenFunction, listenKey) => {
				if (listenKey == this.to.__type) {
					console.log("running an extra listenTo callback for " + listenKey + " on: ", this.parentMagic);
					listenFunction(diff, this.to, opts);
				}
			});
		}
	}

	pushDelta(delta) {
		if (delta == undefined || delta.delta == undefined || delta.delta.__type == undefined) {
			return;
		}
		const existingIdx = _.findIndex(this.deltas, (d) => d.id == delta.id && d.delta.__type == delta.delta.__type);

		if(delta.__deleted == true){
			delta.delta.__deleted = true;
		}
		//merge if we have an existing delta
		if (existingIdx >= 0) {
			this.deltas[existingIdx].delta = { ...this.deltas[existingIdx].delta, ...delta.delta };
		} else {
			this.deltas.push(delta);
		}


		let parentMagic = undefined;
		if(this.parentMagic){
			parentMagic = this.parentMagic;
		} else if(this.me && this.me.context && this.me.context.parentMagic){
			parentMagic = this.me.context.parentMagic;
		}

		if (parentMagic && parentMagic.pushDelta) {
			parentMagic.pushDelta(delta);
		}

		if (this.onDeltaChange != undefined){
			this.onDeltaChange(this);
		}
	}

	helloDad(them) {
		if (this.childBounds == null) {
			this.childBounds = [];
		}

		if (this.me){
			them._parentOnChange = this.me.props.onChange;

			if (this.me.props.boundId != undefined) {
				them._parentBoundId = this.me.props.boundId;
			}
		}

		const existingBound = _.findIndex(this.childBounds, (cb) => cb.to && them.to && cb.to.index == them.to.index);

		if (existingBound == -1) {
			this.childBounds.push(them);
		} else {
			this.childBounds[existingBound] = them;
		}
	}

	magicalState(keyPath, setFunc, options) {
		if ( this.stateWatchers[keyPath] == null){
			this.stateWatchers[keyPath] = [];
		}
		this.stateWatchers[keyPath].push( { setter: setFunc } );

		let skip = false;
		let defaultValue = null;
		if(options != null){
			defaultValue = options.defaultValue;
			skip = options.skipInitialSet? options.skipInitialSet : false;
		}

		const ret = this.magicalGet(keyPath, defaultValue);
		if(!skip){
			setFunc(ret);
		}
		
		return ret;
	}

	
	magicalGet(keyPath, defaultValue) {
		let value = "";

		if (this.to) {
			if (this.to instanceof Component) {
				value = this.to.state[keyPath];
			} else if (keyPath && keyPath.split('.').length == 1) {
				if (this.to[keyPath] != undefined) {
					value = this.to[keyPath];
				}
			} else if (keyPath) {
				var chunks = keyPath.split('.');
				try {
					let ret = this.to[chunks[0]][chunks[1]];
					
					//this is if it's the third element like magicalGet("person.pet.name")
					if (typeof ret == "object" && chunks[2]) {
						ret = ret[chunks[2]];
					}

					value = ret;
				} catch (e) {
					//blindly catch the array not existing...   
				}
			}
		}

		window.DEBUG && console.log("not a valid keypath in magic: " + keyPath);
		if(value === ""){
			if (defaultValue != undefined || defaultValue === null) {
				if (defaultValue != undefined) {
					this.magicalSet(keyPath, defaultValue, { silent: true });
				}
				value = defaultValue;
			}
		}

		return value;
	}

	getGotBounds(){
		return _.values(this._gotBounds);
		//return _.filter(this._gotBounds, (v,k)=>k.indexOf('__self') == -1 )
	}

	updateGotBound(keyPath, pos, newTo){
		if (this._gotBounds){
			this._gotBounds[keyPath + ":" + pos] = newTo;

			if(this.to && _.isArray(this.to[keyPath])){
				this.to[keyPath][pos] = newTo;	
			}			
		}
	}

	getBound(keyPath, pos, options) {
		if (keyPath == null) {
			keyPath = "__self";
		}
		if (this._gotBounds == null) {
			this._gotBounds = {};
		}

		if (this._gotBounds[keyPath + ":" + pos]) {
			return this._gotBounds[keyPath + ":" + pos];
		}

		let item = null;
		let ret = null;
		if (keyPath == "__self") {
			item = this.to;
			ret = { ...item };
			ret._ourMagic = this;
		} else {
			item = this.magicalGet(keyPath);

			let updateCallback = null;

			if (pos != null && _.isArray(item)) {
				item = item[pos];
				updateCallback = (to, diff) => { this.magicalGet(keyPath)[pos] = to; window.theBound = this; this.callbacks(diff); };
			} else {
				updateCallback = (to) => { this.magicalSet(keyPath, to) };
			}

			ret = { ...item };

			if (ret._ourMagic == null) {
				ret._ourMagic = BoundMagic.create(ret, this.me, updateCallback, this, options);
			}
		}

		ret._keyPath = keyPath;
		ret._keyPathPos = pos;

		this.updateGotBound(keyPath, pos, ret);
		return ret;
	}

	magicalMerge(keyPath, newStructure, prop) {
		const a = _.cloneDeep(this.magicalGet(keyPath, []));
		if (a[0] != undefined) {
			a[0][prop] = newStructure[prop];
		}
		this.magicalSet(keyPath, a);

	}

	magicalAppend(keyPath, value, orderBy, orderByDirection) {
		/* this.magicalGet(keyPath, []).push(value);
		this.updateWatchers(keyPath);
		return this.magicalGet(keyPath); */

		let newValue = _.cloneDeep(this.magicalGet(keyPath, []));
		if (_.isArray(value)){
			newValue = newValue.concat( value );
		}else{
			newValue.push(value);
		}

		if(orderBy != undefined){
			newValue = _.orderBy(newValue, orderBy, orderByDirection);
			this.clearGotBounds();
		}

		this.magicalSet(keyPath, newValue);
		return this.magicalGet(keyPath);
	}

	magicalSplice(keyPath, index, forceUpdate) {
		if (Array.isArray(this.magicalGet(keyPath, []))) {
			this.magicalGet(keyPath, []).splice(index, 1);
		}

		if(forceUpdate == true){
			this.clearGotBounds();
			this.magicalSet(keyPath, this.magicalGet(keyPath, []));
		}

		return this.magicalGet(keyPath);
	}

	on(keyPath, handler) {
		if ( this.stateWatchers[keyPath] == null){
			this.stateWatchers[keyPath] = [];
		}
		
		this.stateWatchers[keyPath].push( { watcher: handler } );
	}

	replaceTo(newTo, newParent) {
		if (newTo == null) {			
			return;
		}
		if (!_.isEqual(this.to, newTo)) {
			
			let existingParent = this.to._ourMagic ? this.to._ourMagic.parentMagic : null;
			let existingThings = _.pick(this.to, ['_keyPath', '_keyPathPos']);

			this.to = newTo;
			this.to._ourMagic = this;
			if (newParent != null) {
				this.to._ourMagic.parentMagic = newParent;
			} else if (existingParent != null) {
				this.to._ourMagic.parentMagic = existingParent;
			}

			if (_.size(existingThings) > 0) {
				_.assign(this.to, existingThings);
			}
			this.deltas = [];
			this.updateAllWatches();

			if (_.size(existingThings) > 0) {
				if (this.to._ourMagic.parentMagic) {
					this.to._ourMagic.parentMagic.updateGotBound(existingThings._keyPath, existingThings._keyPathPos, this.to);
				}
			}

		}

		_.each(this.childBounds, (cb) => {
			try {
				//find a way to make this generic?
				if (cb.to && cb.to._keyPath) {
					const replacement = _.find(newTo[cb.to._keyPath], (l) => l.id == cb.me._myMagic.to.id);
					cb.replaceTo(replacement, this);
				}
			} catch (e) {
				//
				debugger;
			}
		});


		if (this._gotBounds) {
			_.each(this._gotBounds, (gotBound, path) => {
				if (_.isObject(newTo[gotBound._keyPath]) && _.isArray(newTo[gotBound._keyPath]) == false) {					
					gotBound._ourMagic.replaceTo(newTo[gotBound._keyPath]);
				} else {
					if (newTo[gotBound._keyPath] != null) {
						gotBound._ourMagic.replaceTo(newTo[gotBound._keyPath][gotBound._keyPathPos]);
					}
				}
			});
			//this.clearGotBounds();
		}

		this.updateWatchers("*");
	}

  clearGotBounds() {
    if (this._gotBounds) {  
      delete this._gotBounds;
    }
  }
}

const BoundView = ({boundTo = undefined, children = undefined, disableEditing = false, overrideEditing = false, TagName = "div"}) =>{
	const parentMagic = useContext(DataContext);
	const [clonedKids, setClonedKids] = useState([]);
	let boundedBy = null;
	let _ourMagic = null;

	if(boundTo == undefined){
		boundedBy = parentMagic.getBound();
	} else if(typeof boundTo == "string"){
		boundedBy = parentMagic.getBound(boundTo);
	} else {
		boundedBy = {...boundTo};
	}
	
	_ourMagic = boundedBy._ourMagic;

	if (_ourMagic == null){
		_ourMagic = boundedBy;
	}
	const disableEditingForSureNothingCanOverrideThis = _ourMagic.me && _ourMagic.me.props.disableEditing? _ourMagic.me.props.disableEditing : false;
	const parentEditing = _ourMagic.me && _ourMagic.me.getIsEditing();
	const [_editing, setEditing] = useState(parentEditing);
	let editing = false;

	//parentEditing takes precedence over all. Then disableEditing and finally local editing
	if(parentEditing == true || (disableEditing == false && _editing == true)){
		editing = true;
	}

	useEffect( ()=>{
		setClonedKids( React.Children.map(children, (child)=>{
			return React.cloneElement(child, {readOnly: disableEditing == true? true : !editing});
		}) );
	}, [children, editing]); 
	

	const toggleEditMode = (event, val)=>{
		if (event != null) event.stopPropagation();		
		if(disableEditing == false){		
			setEditing(val);
		}		
	}	

	if(disableEditingForSureNothingCanOverrideThis == true){
		editing = false;
	}

	useEffect(() => {
		if (overrideEditing == true) setEditing(true)
	}, []);
	
	return <TagName key="bound-view" onClick={(e)=>toggleEditMode(e,true)} className={"boundView" + (disableEditing == false? " editable" : "")}>
		<Bound to={boundedBy}>
			{clonedKids}
			{false && editing && disableEditing == false? <Icon icon="check" onClick={(e)=>toggleEditMode(e,false)}/> : undefined}
		</Bound>
	</TagName>
}

export default class Bound extends Component {
	static propTypes = {
	};
	
	static contextType = DataContext;		

	constructor(props, context) {
		super(props, context);
		this.state = {};

		if (this.props.statePath == null && this.props.updater == null) {
			if (props.to) {
				if (_.isArray(props.to)) {
					throw new Error("@opidcore/Bound need to accept null or a hash/object to keep its state in check.");
				} else {
					if (props.to && props.to._ourMagic) {
						console.log("Bound.ja We took out ourMagic in a Bound, I think this is ok though.");
					}
				}

			}
		}
	}

	getIsEditing(){
		return this.props.editing;
	}

	componentWillUnmount() {
		const boundId = this._myMagic && this._myMagic.boundId ? this._myMagic.boundId : null;

		if (boundId != undefined) {
			APP.unregisterBoundMagic(boundId);

			//unregister my children as well
			_.forEach(APP.registeredBoundMagics, (rbm, rbmKey) => {
				if (rbmKey.indexOf(boundId + "|") > -1) {
					//console.log("unregistering bound " + rbmKey);
					APP.unregisterBoundMagic(rbmKey);
				}
			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.to) {
			if (_.isArray(props.to)) {
				throw new Error("@opidcore/Bound need to accept null or a hash/object to keep its state in check.");
			} else {
				return props.to;
			}
		}

		return null;
	}

	xcomponentWillReceiveProps(nextProps) {
		if (nextProps.children && (this.numberOfChildren != nextProps.children.length)) {
			console.log("Bound.js: we got more children"); 
		}		
	}


	handleChangeList(ourList, comp, item, fieldName, value) {
		const stateChange = {};
		const path = ourList.props.statePath;
		const key = "";
		const key_id = ourList.props.statePathKey;
		var usesKey = true;

		if (this.props.to.state[path] == null) {
			stateChange[path] = [];
		} else {
			if (Array.isArray(this.props.to.state[path])) {
				stateChange[path] = this.props.to.state[path].slice();
				usesKey = false;
			} else {
				stateChange[path] = { ... this.props.to.state[path] };
			}
		}

		var theIndex = item[key_id]
		var arrayIndex = -1;

		_.each(stateChange[path], function (theValue, theKey) {
			if ((theValue.id != null && theValue.id == item.id) || theValue[key_id] == item[key_id] || (theValue._key != null && theValue._key == item[key_id])) {
				arrayIndex = theKey;
				theIndex = theKey;
				usesKey = false;
			}
		});

		if (!Array.isArray(stateChange[path])) {
			usesKey = true;
		}

		if (usesKey) {
			if (stateChange[path][item[key_id]] == null) {
				const newRow = { _key: item[key_id] };
				if (key_id != null) {
					newRow[key_id] = item[key_id];
				}
				stateChange[path][item[key_id]] = newRow;

			}
		} else {
			if (arrayIndex == -1) {
				const newRow = { id: 0, _key: item[key_id] };
				if (key_id != null) {
					newRow[key_id] = item[key_id];
				}
				stateChange[path].push(newRow);
				theIndex = stateChange[path].length - 1; //cody added the -1 blame him if  is broken
			}
		}

		if (value instanceof Object && !Array.isArray(value) && !value._isAMomentObject) { //value is a hash
			_.forEach(Object.keys(value), (key) => {
				stateChange[path][theIndex][key] = value[key];
			});

		} else {
			if (stateChange[path][theIndex][fieldName] == undefined) { //bandaid for action rows in quality alert
				stateChange[path][theIndex]._key = item[key_id];
			}
			stateChange[path][theIndex][fieldName] = value;
		}

		if (this.props.to && this.props.to.setState != null) {
			this.props.to.setState(stateChange);
		}

	}

	handleChange(child, e, a, b, fieldPath) {
		if (this.props.update != null) {
			this.props.update(this.props.statePath, this.props.to, child.props.field, b);
		} else if (fieldPath) {
			const stateChange = {};

			if (fieldPath.indexOf(".") > 0) {
				const chunks = fieldPath.split(".");

				stateChange[chunks[0]] = { ... this.props.to.state[chunks[0]] };

				if (stateChange[chunks[0]][chunks[1]] == null) {
					stateChange[chunks[0]][chunks[1]] = {};
				}

				stateChange[chunks[0]][chunks[1]][a] = b;
			} else {
				stateChange[fieldPath] = { ... this.props.to.state[fieldPath] };

				stateChange[fieldPath][child.props.field || a] = b;
			}

			this.setState(stateChange);

			if (this.props.to && this.props.to.setState != null) {
				this.props.to.setState(stateChange);
			}
		} else {
			const stateChange = { __dirty: true };
			stateChange[child.props.field] = b;

			this.setState(stateChange);

			if (this.props.to && this.props.to.setState != null) {
				this.props.to.setState(stateChange);
			}
		}
	}

	makeSomeMagic(to) {
		let me = new BoundMagic(to, this);

		if (this.context.magicalGet != null || this.context.helloDad != null) {
			this.context.helloDad(me);
			if (this.context.needsADad) {
				this.context.dad = this;
			}
		}

		let boundId = this.props.boundId;

		if (this.props.init) {
			if (this._myMagic != null && this._myMagic.boundId) {
				boundId = this._myMagic.boundId;
			} else {
				boundId = "temporary-bound-" + Math.round(Math.random() * 1000000);
			}

		}



		if (boundId != undefined) {
			let id = boundId;
			if (me._parentBoundId) {
				id = me._parentBoundId + "|" + id;
			}

			if (APP.registeredBoundMagics && APP.registeredBoundMagics[id] != null) {
				me = APP.registeredBoundMagics[id];			
				//me.replaceTo(to);
			} else {
				APP.registerBoundMagic(id, me);
			}

			me.boundId = boundId;
		}

		if (this.props.init) {
			this.props.init(me, this);
			this.initDone = true;
		}

		return me;
	}

	replaceMagicTo(newTo) {
		this._myMagic.replaceTo(newTo);
	}

	render() {
		var obj = this.props.to;
		let TagName = this.props.tagName;

		if (TagName == undefined) {
			if (this.props.flex) {
				TagName = "div";
			} else {
				TagName  = React.Fragment;
			}
		}

		this.numberOfChildren = React.Children.count(this.props.children);

		if (this.props.to && this.props.to._ourMagic) {
			this._myMagic = this.props.to._ourMagic;
		}else if ( BoundMagic.isMagic(this.props.to) ) {
			this._myMagic = this.props.to;
		} else if (this.props.to == null && this.props.parentArray) {
			if (this.context.magicalGet == null) {
				return <TagName key="bound_error">Loading...</TagName>;
			}
			//this.theRealTo = this.context.magicalGet(this.props.parentArray)[this.props.parentArrayIdx];
			this.theRealTo = this.context.getBound(this.props.parentArray, this.props.parentArrayIdx);
			this._myMagic = this.makeSomeMagic(this.theRealTo);
		} else if (_.isString(this.props.to)){
			/*** this lets us use a child bound like this:
			 * <Bound to="pet">...</Bound> 
			 * so it will be bound to the pet field of the parent			 *
			 */
			if (this.context.magicalGet == null) {
				return <TagName key="bound_error">Loading...</TagName>;
			}
			this.boundViaGotBound = this.props.to;
			this.theRealTo = this.context.getBound(this.boundViaGotBound);
			this._myMagic = this.makeSomeMagic(this.theRealTo);
		} else {
			this._myMagic = this.makeSomeMagic(this.props.to);
		}
		var errorLine = null;

		let ourOpts = { className: this.props.className + " " + this.state.statusClass };

		if(this.props.className != undefined && this.props.className != ""){
			if(TagName == React.Fragment){
				TagName = "span";
			}
		}

		if (this.props.flex == true) {
			if (ourOpts.className == undefined) {
				ourOpts.className = "";
			}
			ourOpts.className += " flex_group";
		}

		if (TagName == React.Fragment) {
			ourOpts = {};
		}

		if (this.props.styler) {
			ourOpts.style = this.props.styler();
		}

		if (this.state.statusClass && false) {
			errorLine = <TagName {...ourOpts}>
				<td colSpan="100">{_.map(this.state.statusMessages, (m, index) => <span class="message" key={"statusMessage" + index}><strong>{m}</strong>&nbsp;</span>)}</td>
			</TagName>
		}

    if(this._myMagic && this.initDone != true && this.props.init) {
      this.props.init( this._myMagic, this );
      this.initDone = true;
    }

		let children = undefined;

		if (this.props.flex && Array.isArray(this.props.children)) {
			children = _.map(this.props.children, (child, index) => {
				let className = child.props ? child.props.className : "";
				className += " flex_element";
				return React.cloneElement(child, { key: index, className: className }, (child.props ? child.props.children : null));
			});
		} else {
			children = this.props.children;
		}

		/* children = React.Children.map(this.props.children, (child, index)=>{
			let className = child && child.props ? child.props.className : "";
			className += " flex_element";
			return React.cloneElement(child, { key: index, className: className, disabled: this.props.disabled || false }, (child.props ? child.props.children : null));
		}); */
		
		return <DataContext.Provider value={this._myMagic}>
			<TagName {...ourOpts}>{children}</TagName>
			{errorLine}
		</DataContext.Provider>;
	}

prepChildren(children) {
		return this.mapAllChildren(children, this)[0];
	}

	smartCloneElement(element, props, children) {
		this.clones++;

		var a = React.cloneElement(element, props, children);

		return a;
	}

	mapAllChildren(children, myParent) {
		if (this.clones == undefined) {
			this.clones = 0;
		} else {
			//console.log('So far we have made ' + this.clones);
		}

		var that = this;

		return React.Children.map(children, function (child) {
			if (child == null)
				return;
			let newChild = null;

			let args = {
				obj: that.props.to,
				here: 1,
				'update': (e, a, b) => that.handleChange(child, e, a, b)
			}

			if (that.props.fieldKey) {
				args.obj = that.props.to.state;

				that.props.fieldKey.split(".").map((k) => {
					if (args.obj) {
						args.obj = args.obj[k];
					} else {
						debugger;
					}
				});

				args.fieldKey = that.props.fieldKey;
				args.update = (e, a, b) => that.handleChange(child, e, a, b, that.props.fieldKey);
			
			}

			if (child.type == null) {
				return child;
			}

			if (myParent != null) {
				args.parentBound = myParent;
				if (myParent.update != null) {
					args.update = (e, a, b) => myParent.update(child, e, a, b);
				} else {
					args.update = (e, a, b) => that.handleChange(child, e, a, b);
				}
			}


			if (child.type.prototype instanceof Bound) {
				//return React.cloneElement(child, {parentBound: that}, child.props ? that.mapAllChildren(child.props.children, child) : null);
				return that.smartCloneElement(child, { parentBound: that }, child.props.children);
			} else if (child.type == "asdf") {
				return that.smartCloneElement(child, args, child.props ? that.mapAllChildren(child.props.children, myParent) : null);
			} else if (child.type.prototype instanceof BoundComponent) {
				if ((that.props.disabled == true || that.props.disabled == "true") && (child.props.disabled == undefined || child.props.disabled == true || child.props.disabled == "true")) {
					args.disabled = true;
				}

				return that.smartCloneElement(child, args, child.props ? that.mapAllChildren(child.props.children, myParent) : null);
			} else if (child.type.WrappedComponent && (child.type.WrappedComponent.name == "DocumentFilter" || child.type.WrappedComponent.name == "CustomerFilter" || child.type.WrappedComponent.name == "EmployeeFilter" || child.type.WrappedComponent.name == "InputMultiPeopleSearch" || child.type.WrappedComponent.name == "CrossFunctionalTeamRow" || child.type.WrappedComponent.name == "CustomerContactFilter" || child.type.WrappedComponent.name == "ReportFilter")) {
				if ((that.props.disabled == true || that.props.disabled == "true") && (child.props.disabled == undefined || child.props.disabled == true || child.props.disabled == "true")) {
					args.disabled = true;
				}

				return that.smartCloneElement(child, args, null);
			} else {
				return that.smartCloneElement(child, {}, child.props ? that.mapAllChildren(child.props.children, myParent) : null);
			}



		});

	}
}

const useMagic = (to, options) =>{
	const [boundMagic, setBoundMagic] = useState(BoundMagic.create(to, null, null, null, options));

	return boundMagic;
}

/** @param to - the field to watch for changes
 *  @param setter - the setter to tie it to
 * @example  
  		<Bound to={}>
			<MagicalState to="" setter={setPetName}/>
		</Bound> 
*/
const MagicalState = ({to, setter})=>{
    const bound = useContext(DataContext);

    useEffect( ()=>{
        bound.magicalState(to, setter);
    },[bound]);

    return null;
}

MagicalState.propTypes = {
    to: PropTypes.string,
    setter: PropTypes.func
};

export { BoundView, MagicalState, useMagic };